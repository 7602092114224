/**
 * Company action creator
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2021
 */
import { companyTypes as types } from './types'
const getAll = (params, canSave = true) => ({
	type: types.getAll,
	payload: {
		request: {
			method: 'GET',
			url: `/company`,
			params: { MaxRecords: 0, ...params }
		}
	},
	canSave
})
const getAllByPage = (autotaskId, params) => ({
	type: types.getAll,
	payload: {
		request: {
			method: 'GET',
			url: `/company/${autotaskId}/pageUrl`,
			params
		}
	}
})
const getLocations = companyId => ({
	type: types.getLocations,
	payload: {
		request: {
			method: 'GET',
			url: `/company/${companyId}/locations`
		}
	}
})
const getCompanyById = companyId => ({
	//companyId is autotaskId
	type: types.getCompanyById,
	payload: {
		request: {
			method: 'GET',
			url: `/company/${companyId}`
		}
	}
})
const createLocation = (autotaskId, data) => ({
	type: types.createLocation,
	payload: {
		request: {
			method: 'POST',
			url: `/company/${autotaskId}/location`,
			data
		}
	}
})
const editLocation = (autotaskId, data) => ({
	type: types.editLocation,
	payload: {
		request: {
			method: 'PATCH',
			url: `/company/${autotaskId}/location`,
			data
		}
	}
})
const editCompanyDetails = (autotaskId, data) => ({
	type: types.editCompanyDetails,
	payload: {
		request: {
			method: 'PATCH',
			url: `/company/${autotaskId}`,
			data
		}
	}
})
const getCompanyContacts = (companyId, params) => ({
	type: types.getCompanyContacts,
	payload: {
		request: {
			method: 'GET',
			url: `/users/company/${companyId}`,
			params: { MaxRecords: 0, ...params }
		}
	}
})
const grantAulaAccess = companyId => ({
	type: types.grantCompanyAccess,
	payload: {
		request: {
			method: 'POST',
			url: `/company/autotaskId/${companyId}`
		}
	}
})
const grantEcommerceAccess = companyId => ({
	type: types.grantCompanyAccess,
	payload: {
		request: {
			method: 'POST',
			url: `/ecommerce/company/companyAutotaskId/${companyId}`
		}
	}
})
const removeAulaAccess = companyId => ({
	type: types.grantCompanyAccess,
	payload: {
		request: {
			method: 'DELETE',
			url: `/company/${companyId}`
		}
	}
})
const removeEcommerceAccess = autotaskId => ({
	type: types.grantCompanyAccess,
	payload: {
		request: {
			method: 'DELETE',
			url: `/ecommerce/company/companyAutotaskId/${autotaskId}`
		}
	}
})
const clearContactsAndLocations = () => ({
	type: types.clearContactsAndLocations
})
export default {
	getLocations,
	getCompanyById,
	createLocation,
	getCompanyContacts,
	getAll,
	getAllByPage,
	grantAulaAccess,
	removeAulaAccess,
	clearContactsAndLocations,
	grantEcommerceAccess,
	editLocation,
	editCompanyDetails,
	removeEcommerceAccess
}
