/**
 * Notification reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { notificationTypes as types } from '../actions/types'
import { errorUtils } from 'utils'
import { NOTIFICATION_TYPE, NOTIFICATION_DURATION } from 'constants'
import { get } from 'lodash'

const initialState = {
	notification: null
}
/**
 *
 *
 * @param {Object}
 *
 * @return {Array}
 */
const getDuration = error => {
	if (error.response && error.response.data && error.response.data.code) {
		switch (error.response.data.code) {
			case 1042:
				return 4000
			default:
				return NOTIFICATION_DURATION
		}
	}
	return NOTIFICATION_DURATION
}
export const notificationReducer = (state = initialState, action) => {
	if (action.type.endsWith('_FAIL')) {
		// TODO: REMOVE THIS IF YOU ARE IMPLEMENTING ECOMMERCE (temporary fix, only for this release)
		// This hides "Company with autotask id ${autotaskId} does not have access to Ecommerce." errors
		if (get(action, 'error.response.data.code', undefined) === 1051)
			return { ...state }
		return {
			...state,
			notification: {
				type: NOTIFICATION_TYPE.error,
				message: errorUtils.normalizeError(action.error).message,
				duration: getDuration(action.error)
			}
		}
	}
	switch (action.type) {
		case types.showNotification:
			return { ...state, notification: action.notification }
		case types.resetNotification:
			return initialState
		default:
			return state
	}
}

export const notificationSelectors = {
	getNotification: (state = initialState) => state.notification
}
