/**
 * User reducer
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { loginTypes, userTypes } from '../actions/types'

const initialState = {
	user: null,
	access: null,
	refresh: null,
	loading: false,
	error: null
}

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case userTypes.getUser:
			return {
				...state,
				loading: true
			}
		case userTypes.getNewAccessTokenSuccess:
			return {
				...state,
				access: action.payload.data.access
			}
		case loginTypes.googleLoginSuccess:
			localStorage.setItem('userId', action.payload.data.id)
			localStorage.setItem(
				'companyId',
				action.payload.config.reduxSourceAction.companyId
			)
			return {
				...state,
				user: action.payload.data,
				loading: false,
				access: action.payload.config.reduxSourceAction.token,
				refresh: action.payload.config.reduxSourceAction.refreshToken
			}

		case loginTypes.loginSuccess:
			localStorage.setItem('userId', action.payload.data.user.id)
			localStorage.setItem('companyId', action.payload.data.user.company.autoTaskId)
			return { ...state, ...action.payload.data, loading: false }
		case userTypes.getUserFail:
			return {
				...state,
				loading: false
			}
		case userTypes.updateUserPreferences:
			return {
				...state,
				loading: false
			}

		case userTypes.updateUserPreferencesSuccess:
			return {
				...state,
				loading: false,
				user: { ...state.user, preferences: action.payload.data.preferences }
			}
		case userTypes.updateUserPreferencesFail:
			return {
				...state,
				loading: false
			}
		default:
			return state
	}
}

export const userSelectors = {
	getUser: (state = initialState) => state.user,
	getRole: (state = initialState) => state.user.aulaRole,
	getRefreshToken: (state = initialState) => state.refresh,
	isAuthenticated: (state = initialState) => !!state.user,
	getLoading: (state = initialState) => state.loading
}
