/**
 * Manage assets
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2021
 */
import logo from 'assets/logo.png'
import color_logo from 'assets/color_logo.png'
import orderMenuNoti from 'assets/order_menu_noti.png'
import userMenu from 'assets/user_menu.png'
import tagMenu from 'assets/tag_menu.png'
import dashboardMenu from 'assets/dashboard_menu.png'
import trainingMenu from 'assets/training_menu.png'
import deviceMenu from 'assets/device_menu.png'
import documentMenu from 'assets/document_menu.png'
import serviceRequestMenu from 'assets/service_request_menu.png'
import cartTag from 'assets/cart_tag.png'
import bullhorn from 'assets/bullhorn.png'
import importIcon from 'assets/import.png'
import exportIcon from 'assets/export.png'
import backIcon from 'assets/back.png'
import reportMenu from 'assets/report_menu.png'
import companiesMenu from 'assets/companies_menu.png'

export {
	backIcon,
	importIcon,
	exportIcon,
	cartTag,
	bullhorn,
	logo,
	color_logo,
	orderMenuNoti,
	userMenu,
	tagMenu,
	dashboardMenu,
	trainingMenu,
	deviceMenu,
	documentMenu,
	serviceRequestMenu,
	reportMenu,
	companiesMenu
}
