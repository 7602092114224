/**
 * Request reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { serviceRequestTypes as types } from '../actions/types'
import { serviceRequestModule } from '../modules'
import apiStatus from 'modules/status'

const initialState = {
	request: null,
	attachments: [],
	status: apiStatus.idle,
	attachmentsStatus: apiStatus.idle,
	error: null
}

export const serviceRequestReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.getOne:
			return { ...state, status: apiStatus.pending }
		case types.getOneSuccess:
			return {
				...state,
				status: apiStatus.resolved,
				request: action.payload.data
			}
		case types.getOneFail:
			return { ...state, status: apiStatus.reject }
		case types.createOne:
			return { ...state, status: apiStatus.pending }
		case types.createOneSuccess:
			return { ...state, status: apiStatus.resolved }
		case types.createOneFail:
			return { ...state, status: apiStatus.reject }
		case types.initRequest:
			return {
				...state,
				request: {
					...serviceRequestModule.serviceRequestModel,
					...action.request
				}
			}
		case types.getSelectedRequest:
			return {
				...state,
				request: {
					...serviceRequestModule.serviceRequestModel,
					...action.request
				}
			}
		case types.getTicketAttachments:
			return {
				...state,
				attachmentsStatus: apiStatus.pending
			}
		case types.getTicketAttachmentsSuccess:
			return {
				...state,
				attachments: action.payload.data,
				attachmentsStatus: apiStatus.revolved
			}
		case types.getTicketAttachmentsFail:
			return {
				...state,
				attachments: action.payload.data,
				attachmentsStatus: apiStatus.reject
			}
		case types.resetState:
			return initialState
		default:
			return state
	}
}

export const serviceRequestSelectors = {
	getRequest: (state = initialState) => state.request,
	getAttachments: (state = initialState) => state.attachments,
	getAttachmentsStatus: (state = initialState) => state.attachmentsStatus
}
