/**
 * Device reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { orderTypes as types } from '../actions/types'
import apiStatus from 'modules/status'

const initialState = {
	order: null,
	status: apiStatus.idle,
	error: null
}

export const orderReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.getOrder:
			return { ...state, order: action.order }
		case types.resetOrder:
			return initialState
		default:
			return state
	}
}

export const orderSelectors = {
	getOrder: (state = initialState) => state.order
}
