/**
 * Service request modules helper
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
// actions
import { serviceRequestActions } from 'actions'
// reducers
import { serviceRequestsSelectors, companySelectors } from 'reducers'
// module
import apiStatus from 'modules/status'
import moment from 'moment'
//constants
import { AUTOTASK_ROLES } from 'constants'
// utils
import { arrayUtils } from 'utils'

const serviceRequestModel = {
	companyID: '',
	description: '',
	contactID: '',
	issueType: 12,
	priority: 3,
	queueID: 29682833,
	source: 5,
	subIssueType: 150,
	status: 1,
	title: 'New ticket',
	billingCodeID: 29682801
}
/**
 * requests hook
 * Fetch service request from server and save data to the state
 * once the request is resolved
 *
 * @return {Array} a list of service request fetched from server
 */

const useRequests = createDate => {
	const dispatch = useDispatch()

	const fetchedRequests = useSelector(state =>
		serviceRequestsSelectors.getRequests(state.serviceRequests)
	)
	const itemsPerPage = useSelector(state =>
		serviceRequestsSelectors.getItemsPerPage(state.serviceRequests)
	)

	const prevPageUrl = useSelector(state =>
		serviceRequestsSelectors.getPrevPageUrl(state.serviceRequests)
	)
	const nextPageUrl = useSelector(state =>
		serviceRequestsSelectors.getNextPageUrl(state.serviceRequests)
	)
	const companyId = localStorage.getItem('companyId')
	const company = useSelector(state =>
		companySelectors.getCompany(state.company)
	)
	const status = useSelector(state =>
		serviceRequestsSelectors.getApiStatus(state.serviceRequests)
	)
	const [requests, setRequests] = React.useState(fetchedRequests)

	React.useEffect(() => {
		if (company) {
			// dispatch(serviceRequestActions.clearTickets())
			getTicketsRequest()
		}
	}, [company])
	const getTicketsRequest = async () => {
		if (company) {
			try {
				await dispatch(
					serviceRequestActions.getAll(
						companyId,
						{
							createDate,
							inputDate: moment()
								.utc()
								.format()
						},
						!!(requests && requests.length)
					)
				)
			} catch (err) {}
		}
	}
	React.useEffect(() => {
		if (status === apiStatus.resolved) {
			setRequests(fetchedRequests)
		}
	}, [status, fetchedRequests])
	return { requests, prevPageUrl, nextPageUrl, status, itemsPerPage }
}
const normalizeContacts = data => {
	const primaryUsers = data.reduce((accumulator, user) => {
		if (user.autotaskRole === AUTOTASK_ROLES.primary_contact) {
			return accumulator.concat([
				{
					...user,
					value: user.autoTaskId,
					label: user.lastName + ' ' + user.firstName
				}
			])
		}
		return accumulator
	}, [])
	const normalUsers = data.reduce((accumulator, user) => {
		if (user.autotaskRole !== AUTOTASK_ROLES.primary_contact) {
			return accumulator.concat([
				{
					...user,
					value: user.autoTaskId,
					label: user.lastName + ' ' + user.firstName
				}
			])
		}
		return accumulator
	}, [])
	const newData = primaryUsers.concat(
		normalUsers.sort((a, b) => arrayUtils.sortString(a.label, b.label, 1))
	)
	if (!newData.length) return []
	return newData
}
export default { serviceRequestModel, useRequests, normalizeContacts }
