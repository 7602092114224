/**
 * Root reducer
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()
import { loginTypes as types } from '../actions/types'
import storage from 'redux-persist/es/storage'
import { PERSIST_KEY } from 'constants'
// Login
import { loginReducer, loginSelectors } from './login'
// User
import { userReducer, userSelectors } from './user'
import { usersReducer, usersSelectors } from './users'
import { companyReducer, companySelectors } from './company'
import { companiesReducer, companiesSelectors } from './companies'
import {
	serviceRequestsReducer,
	serviceRequestsSelectors
} from './serviceRequests'
import { devicesReducer, devicesSelectors } from './devices'
import { ordersReducer, ordersSelectors } from './orders'
import { orderReducer, orderSelectors } from './order'
import { locationsReducer, locationsSelectors } from './locations'
import { locationReducer, locationSelectors } from './location'
import { notificationReducer, notificationSelectors } from './notification'
import {
	serviceRequestReducer,
	serviceRequestSelectors
} from './serviceRequest'

// export root reducer
const index = combineReducers({
	router: connectRouter(history),
	login: loginReducer,
	user: userReducer,
	users: usersReducer,
	company: companyReducer,
	companies: companiesReducer,
	serviceRequests: serviceRequestsReducer,
	devices: devicesReducer,
	locations: locationsReducer,
	location: locationReducer,
	serviceRequest: serviceRequestReducer,
	notification: notificationReducer,
	orders: ordersReducer,
	order: orderReducer
})
export default (state, action) => {
	if (
		action.type === types.logoutSuccess ||
		action.type === types.localLogout
	) {
		storage.removeItem(PERSIST_KEY)
		state = undefined
	}
	return index(state, action)
}
// export history
export { history }

// export getters/ selectors
export {
	loginSelectors,
	userSelectors,
	usersSelectors,
	companySelectors,
	serviceRequestsSelectors,
	devicesSelectors,
	locationsSelectors,
	locationSelectors,
	serviceRequestSelectors,
	notificationSelectors,
	companiesSelectors,
	ordersSelectors,
	orderSelectors
}
