/**
 * Location reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { locationTypes as types } from '../actions/types'
import { locationModule } from './../modules'

const initialState = {
	location: null,
	error: null
}

export const locationReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.getSelectedLocation:
			return {
				...state,
				location: { ...locationModule.locationModel, ...action.location }
			}
		case types.resetState:
			return initialState
		default:
			return state
	}
}

export const locationSelectors = {
	getLocation: (state = initialState) => state.location
}
