/**
 * User action creators
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { userTypes as types } from './types'
import { LIST_RECORDS } from 'constants'

const getNewAccessToken = data => {
	return {
		type: types.getNewAccessToken,
		payload: {
			request: {
				method: 'POST',
				url: `/auth/token/refresh`,
				data
			}
		}
	}
}
const updateUser = (userId, data) => {
	return {
		type: types.updateUser,
		payload: {
			request: {
				method: 'PATCH',
				url: `/users/${userId}`,
				data
			}
		}
	}
}
const updateEcommerceUser = (userAutoTaskId, companyAutoTaskId, data) => {
	return {
		type: types.updateEcommerceUser,
		payload: {
			request: {
				method: 'PATCH',
				url: `/ecommerce/user/userAutotaskId/${userAutoTaskId}/companyAutoTaskId/${companyAutoTaskId}`,
				data
			}
		}
	}
}
const removeEcommerceUser = (userAutoTaskId, companyAutoTaskId) => {
	return {
		type: types.removeUser,
		payload: {
			request: {
				method: 'DELETE',
				url: `/ecommerce/user/userAutotaskId/${userAutoTaskId}/companyAutoTaskId/${companyAutoTaskId}`
			}
		}
	}
}
const updateUserInfo = (companyAutoTaskId, data) => {
	return {
		type: types.updateUserInfo,
		payload: {
			request: {
				method: 'PATCH',
				url: `/users/company/${companyAutoTaskId}`,
				data
			}
		}
	}
}
const updateUserPreferences = (userId, data) => {
	return {
		type: types.updateUserPreferences,
		payload: {
			request: {
				method: 'PATCH',
				url: `/users/${userId}`,
				data
			}
		}
	}
}
//for aula
const createAulaUser = (autoTaskId, companyId, data) => {
	return {
		type: types.createAulaUser,
		payload: {
			request: {
				method: 'POST',
				url: `/users/userAutoTaskId/${autoTaskId}/companyAutoTaskId/${companyId}/create`,
				data
			}
		}
	}
}
const createEcommerceUser = (userAutoTaskId, companyAutoTaskId, data) => {
	return {
		type: types.createEcommerceUser,
		payload: {
			request: {
				method: 'POST',
				url: `/ecommerce/user/userAutotaskId/${userAutoTaskId}/companyAutoTaskId/${companyAutoTaskId}`,
				data
			}
		}
	}
}
const getUser = (userId, token, refreshToken, companyId) => {
	return {
		type: types.getUser,
		payload: {
			request: {
				method: 'GET',
				url: `/users/${userId}`,
				headers: {
					authorization: `Bearer ${token}`
				}
			}
		},
		token,
		refreshToken,
		companyId
	}
}
const getCompanyUsers = (companyId, params, isBackground = false) => {
	return {
		type: types.getCompanyUsers,
		payload: {
			request: {
				method: 'GET',
				url: `/users/company/${companyId}`,
				params: { MaxRecords: 0, ...params }
			}
		},
		isBackground
	}
}

const getSelectedUser = user => {
	return { type: types.getSelectedUser, user }
}
const changeItemsPerPage = amount => {
	return {
		type: types.changeItemsPerPage,
		amount
	}
}
const importUsers = (companyId, data) => {
	return {
		type: types.importUsers,
		payload: {
			request: {
				method: 'POST',
				url: `/users/importCsvData/companyId/${companyId}`,
				data
			}
		}
	}
}
const updateUsers = (autotaskCompanyId, data) => {
	return {
		type: types.updateUsers,
		payload: {
			request: {
				method: 'PATCH',
				url: `/users/editGroupOfUsers/autotaskCompanyId/${autotaskCompanyId}`,
				data
			}
		}
	}
}
const fetchUserById = autotaskUserId => {
	return {
		type: types.fetchUserById,
		payload: {
			request: {
				method: 'GET',
				url: `/users/${autotaskUserId}`
			}
		}
	}
}
//remove user from VMIT Database
const removeUser = (companyAutoTaskId, userAutoTaskId) => {
	return {
		type: types.removeUser,
		payload: {
			request: {
				method: 'DELETE',
				url: `/users/company/${companyAutoTaskId}/user/${userAutoTaskId}`
			}
		}
	}
}
const clearUsers = () => {
	return {
		type: types.clearUsers
	}
}
export default {
	getUser,
	getSelectedUser,
	getCompanyUsers,
	getNewAccessToken,
	updateUser,
	createAulaUser,
	updateUserPreferences,
	changeItemsPerPage,
	importUsers,
	updateUsers,
	fetchUserById,
	updateUserInfo,
	clearUsers,
	removeUser,
	createEcommerceUser,
	updateEcommerceUser,
	removeEcommerceUser
}
