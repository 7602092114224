/**
 * Protected route
 * Only let user access the route if user has been authenticated
 * Otherwise redirect user to login page
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ErrorBoundary from 'shared/components/ErrorBoundary'
import { useAuthorization, authorizedState } from '../../../hooks'
import { useSelector, useDispatch } from 'react-redux'
// constants
import { routerPath } from 'constants'
// modules
import { userModule } from 'modules'

// reducers
import { userSelectors } from 'reducers'
// Wrap title and meta data to Component
const withHelmet = (component, title) => (
	<>
		<Helmet>
			<title>{title}</title>
			<meta name='description' content={`Vertics boilerplate | ${title}`} />
		</Helmet>
		{component}
	</>
)

const ProtectedRoute = ({
	component: Component,
	title,
	unauthenticatedRedirect = routerPath.login,
	unauthorizedRedirect = '/no-service',
	protectedRoles,
	...rest
}) => {
	const userRole = useSelector(state => userSelectors.getRole(state.user))
	const { isAuthorized, isAuthenticated } = useAuthorization()
	if (
		protectedRoles &&
		protectedRoles.length > 0 &&
		protectedRoles.every(role => role !== userRole)
	) {
		const redirectRoute = userModule.getRedirectAuthenticatedRoute(userRole)
		return <Redirect to={redirectRoute} />
	}
	if (!isAuthenticated) {
		return <Redirect to={unauthenticatedRedirect} />
	}

	switch (isAuthorized) {
		case authorizedState.idle:
			return null

		case authorizedState.pending:
			return <p>Loading...</p>

		case authorizedState.rejected:
			return <Redirect to={unauthorizedRedirect} />
	}

	return (
		<ErrorBoundary>
			<Route
				{...rest}
				render={props => withHelmet(<Component {...props} />, title)}
			/>
		</ErrorBoundary>
	)
}

export default ProtectedRoute
