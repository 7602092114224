/**
 * Navbar component
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import { routerPath, AULA_ROLES } from 'constants'
import { useSelector, useDispatch } from 'react-redux'
import withLoadingScreen from 'hocs/Loading'
import Switch from '@material-ui/core/Switch'
// Utils
import { localStorageUtils } from 'utils'
// actions
import { loginActions } from 'actions'
// reducer
import { userSelectors, companySelectors } from 'reducers'
import {
	color_logo,
	orderMenuNoti,
	userMenu,
	tagMenu,
	dashboardMenu,
	trainingMenu,
	deviceMenu,
	documentMenu,
	serviceRequestMenu,
	reportMenu,
	companiesMenu
} from 'assets'

import Button from 'shared/components/Button'
import LocalizationButton from 'shared/components/LocalizationButton'
const Nav = ({ showLoading, hideLoading }) => {
	const dispatch = useDispatch()
	const location = useLocation()
	const history = useHistory()
	const { t, i18n } = useTranslation()

	const user = useSelector(state => userSelectors.getUser(state.user))
	const userRole = useSelector(state => userSelectors.getRole(state.user))
	const refreshToken = useSelector(state =>
		userSelectors.getRefreshToken(state.user)
	)
	const [language, setLanguage] = useState(true)
	const company = useSelector(state =>
		companySelectors.getCompany(state.company)
	)
	const { firstName, lastName } = user
	// useEffect(() => {
	// 	if (language) {
	// 		i18n.changeLanguage('en')
	// 		localStorage.setItem('language', 'en')
	// 	} else {
	// 		i18n.changeLanguage('fi')
	// 		localStorage.setItem('language', 'fi')
	// 	}
	// }, [language])
	const onMenuClick = path => {
		history.push(path)
	}
	const onLanguageChange = e => {
		setLanguage(e.target.checked)
	}
	const logout = async () => {
		try {
			showLoading()
			const res = await dispatch(loginActions.logout(refreshToken))
			if (res.payload.data) {
				localStorageUtils.removeTokens()
				hideLoading()
			}
		} catch (e) {
			hideLoading()
		}
	}
	const getUsernameAbb = () => {
		const firstNameLetter = firstName ? firstName.charAt(0) : ''
		const lastNameLetter = lastName ? lastName.charAt(0) : ''
		return `${firstNameLetter}${lastNameLetter}`
	}
	const renderMenu = () => {
		const adminData = [
			{
				icon: <img src={dashboardMenu} alt='Dashboard' />,
				label: t('MENU.DASHBOARD'),
				path: routerPath.adminDashboard,
				activeCheck: routerPath.adminDashboard,
				disabled: !company
			},
			{
				icon: <img src={reportMenu} alt='Reports' />,
				label: t('MENU.REPORTS'),
				path: routerPath.adminReport,
				activeCheck: routerPath.adminReport,
				disabled: !company
			},
			{
				icon: <img src={companiesMenu} alt='Companies' />,
				label: t('MENU.USER_MANAGEMENT'),
				path: '/admin/company/companies',
				activeCheck: '/admin/company',
				disabled: !company
			},
			{
				icon: <img src={serviceRequestMenu} alt='service request icon' />,
				label: t('MENU.SERVICE_REQUEST'),
				path: '/serviceRequests/list',
				activeCheck: '/serviceRequests',
				disabled: !company
			},
			{
				icon: <img src={deviceMenu} alt='device icon' />,
				label: t('MENU.EQUIPMENT'),
				path: routerPath.devices,
				activeCheck: routerPath.devices,
				disabled: !company
			},
			{
				icon: <img src={orderMenuNoti} alt='cart' />,
				label: t('MENU.ORDERS'),
				path: routerPath.orders,
				disabled: false
			}
			/* TODO: hide in production */
			// {
			// 	icon: <img src={orderMenuNoti} alt='cart' />,
			// 	label: t('MENU.ORDERS'),
			// 	path: routerPath.orders,
			// 	disabled: true
			// },
			// {
			// 	icon: <img src={tagMenu} alt='tagMenu' />,
			// 	label: t('MENU.QUOTATION_REQUESTS'),
			// 	path: '/contactRequests/latest',
			// 	activeCheck: '/contactRequests',
			// 	disabled: true
			// }
		]

		const data = [
			{
				icon: <img src={dashboardMenu} alt='Dashboard' />,
				label: t('MENU.DASHBOARD'),
				path: routerPath.dashboard,
				activeCheck: routerPath.dashboard,
				disabled: !company
			},
			{
				icon: <img src={userMenu} alt='user management' />,
				label: t('MENU.USER_MANAGEMENT'),
				path: '/userManagement',
				activeCheck: '/userManagement',
				disabled: !company
			},
			{
				icon: <img src={serviceRequestMenu} alt='service request icon' />,
				label: t('MENU.SERVICE_REQUEST'),
				path: '/serviceRequests/list',
				activeCheck: '/serviceRequests',
				disabled: !company
			},
			{
				icon: <img src={deviceMenu} alt='device icon' />,
				label: t('MENU.EQUIPMENT'),
				path: routerPath.devices,
				activeCheck: routerPath.devices,
				disabled: !company
			},
			{
				icon: <img src={orderMenuNoti} alt='cart' />,
				label: t('MENU.ORDERS'),
				path: routerPath.orders,
				disabled: false
			}
			/* TODO: hide in production */

			// {
			// 	icon: <img src={orderMenuNoti} alt='cart' />,
			// 	label: t('MENU.ORDERS'),
			// 	path: routerPath.orders,
			// 	disabled: true
			// },
			// {
			// 	icon: <img src={tagMenu} alt='tagMenu' />,
			// 	label: t('MENU.QUOTATION_REQUESTS'),
			// 	path: '/contactRequests/latest',
			// 	activeCheck: '/contactRequests',
			// 	disabled: true
			// },
			// {
			// 	icon: <img src={trainingMenu} alt='training icon' />,
			// 	label: t('MENU.TRAINING_AND_INFORMATION'),
			// 	path: '/training/incoming',
			// 	activeCheck: '/training',
			// 	disabled: true
			// },
			// {
			// 	icon: <img src={documentMenu} alt='document icon' />,
			// 	label: t('MENU.DOCUMENT_AND_AGREEMENT'),
			// 	path: '/home',
			// 	disabled: true
			// }
		]
		const dataByRole = userRole === AULA_ROLES.super_admin ? adminData : data
		return dataByRole.map((item, key) => (
			<MenuItem
				key={key}
				menu={item}
				isActive={location.pathname.startsWith(item.activeCheck)}
				onClick={onMenuClick}
				disabled={item.disabled}
			/>
			
		))
	}
	return (
		<div className='core-layout__nav'>
			<img src={color_logo} alt='Logo' className='logo' />
			<div className='core-layout__nav-avatar'>
				<span>{getUsernameAbb()}</span>
			</div>
			<h3 className='core-layout__nav-user'>{`${firstName ? firstName : ''} ${
				lastName ? lastName : ''
			}`}</h3>
			{/* <Switch
				checked={language}
				onChange={onLanguageChange}
				name='checkedA'
				inputProps={{ 'aria-label': 'secondary checkbox' }}
			/> */}
			<div className='core-layout__nav-menu'>{renderMenu()}</div>
			<Button text={t('MENU.LOG_OUT')} onClick={logout} />

			<div className='core-layout__nav__localization'>
				<div className='core-layout__nav__localization-row row1'>
					<LocalizationButton lang='fi'/>
				</div>
				<div className='core-layout__nav__localization-row row2'>
					<LocalizationButton lang='en'/>
				</div>
			</div>
		</div>
	)
}

const MenuItem = ({ menu, isActive, onClick, disabled }) => {
	let className = ['menu-item']
	if (isActive) {
		className.push('active')
	}
	if (disabled) {
		className.push('disabled')
	}
	const _onClick = () => {
		if (disabled) {
			return
		}
		if (onClick && typeof onClick === 'function') {
			return onClick(menu.path)
		}
	}
	return (
		<div className={className.join(' ')} onClick={_onClick}>
			<div className='menu-icon'>{menu.icon}</div>
			<span>{menu.label}</span>
		</div>
	)
}
export default withLoadingScreen(Nav)
