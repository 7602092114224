/**
 * Declare all types and export
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

// Login
export const loginTypes = {
	changeField: 'LOGIN_CHANGE_FIELD',
	login: 'LOGIN',
	loginSuccess: 'LOGIN_SUCCESS',
	loginFail: 'LOGIN_FAIL',
	forgetPassword: 'FORGET_PASSWORD',
	forgetPasswordSuccess: 'FORGET_PASSWORD_SUCCESS',
	forgetPasswordFail: 'FORGET_PASSWORD_FAIL',
	resetPassword: 'RESET_PASSWORD',
	resetPasswordSuccess: 'RESET_PASSWORD_SUCCESS',
	resetPasswordFail: 'RESET_PASSWORD_FAIL',
	localLogout: 'LOGOUT_LOCAL',
	logout: 'LOGOUT',
	logoutSuccess: 'LOGOUT_SUCCESS',
	logoutFail: 'LOGOUT_FAIL',
	googleLogin: 'GOOGLE_LOGIN',
	googleLoginSuccess: 'GOOGLE_LOGIN_SUCCESS',
	googleLoginFail: 'GOOGLE_LOGIN_FAIL'
}
//notification
export const notificationTypes = {
	showNotification: 'NOTIFICATION_SHOW_NOTIFICATION',
	resetNotification: 'NOTIFICATION_RESET_NOTIFICATION'
}

// User
export const userTypes = {
	getUser: 'USER_GET_USER',
	getUserSuccess: 'USER_GET_USER_SUCCESS',
	getUserFail: 'USER_GET_USER_FAIL',
	removeUser: 'USER_REMOVE_USER',
	removeUserSuccess: 'USER_REMOVE_USER_SUCCESS',
	removeUserFail: 'USER_REMOVE_USER_FAIL',
	fetchUserById: 'USER_FETCH_USER_BY_ID',
	fetchUserByIdSuccess: 'USER_FETCH_USER_BY_ID_SUCCESS',
	fetchUserByIdFail: 'USER_FETCH_USER_BY_ID_FAIL',
	importUsers: 'USER_IMPORT_USERS',
	importUsersSuccess: 'USER_IMPORT_USERS_SUCCESS',
	importUsersFail: 'USER_IMPORT_USERS_FAIL',
	updateUser: 'USER_UPDATE_USER',
	updateUserSuccess: 'USER_UPDATE_USER_SUCCESS',
	updateUserFail: 'USER_UPDATE_USER_FAIL',
	updateEcommerceUser: 'USER_UPDATE_ECOMMERCE_USER',
	updateEcommerceUserSuccess: 'USER_UPDATE_ECOMMERCE_USER_SUCCESS',
	updateEcommerceUserFail: 'USER_UPDATE_ECOMMERCE_USER_FAIL',
	updateUserInfo: 'USER_UPDATE_USER_INFO',
	updateUserInfoSuccess: 'USER_UPDATE_USER_INFO_SUCCESS',
	updateUserInfoFail: 'USER_UPDATE_USER_INFO_FAIL',
	updateUsers: 'USER_UPDATE_USERS',
	updateUsersSuccess: 'USER_UPDATE_USERS_SUCCESS',
	updateUsersFail: 'USER_UPDATE_USERS_FAIL',
	updateUserPreferences: 'USER_UPDATE_PREFERENCES',
	updateUserPreferencesSuccess: 'USER_UPDATE_PREFERENCES_SUCCESS',
	updateUserPreferencesFail: 'USER_UPDATE_PREFERENCES_FAIL',
	createAulaUser: 'USER_CREATE_AULA_USER',
	createAulaUserSuccess: 'USER_CREATE_AULA_USER_SUCCESS',
	createAulaUserFail: 'USER_CREATE_AULA_USER_FAIL',
	createEcommerceUser: 'USER_CREATE_ECOMMERCE_USER',
	createEcommerceUserSuccess: 'USER_CREATE_ECOMMERCE_USER_SUCCESS',
	createEcommerceUserFail: 'USER_CREATE_ECOMMERCE_USER_FAIL',
	getSelectedUser: 'USER_GET_SELECTED_USER',
	getCompanyUsers: 'USER_GET_COMPANY_USERS',
	getCompanyUsersSuccess: 'USER_GET_COMPANY_USERS_SUCCESS',
	getCompanyUsersFail: 'USER_GET_COMPANY_USERS_FAIL',
	getNewAccessToken: 'USER_GET_NEW_ACCESS_TOKEN',
	getNewAccessTokenSuccess: 'USER_GET_NEW_ACCESS_TOKEN_SUCCESS',
	getNewAccessTokenFail: 'USER_GET_NEW_ACCESS_TOKEN_FAIL',
	changeItemsPerPage: 'USER_CHANGE_ITEMS_PER_PAGE',
	clearUsers: 'USER_CLEAR_USERS'
}
export const companyTypes = {
	getAll: 'COMPANY_GET_ALL',
	getAllSuccess: 'COMPANY_GET_ALL_SUCCESS',
	getAllFail: 'COMPANY_GET_ALL_FAIL',
	grantCompanyAccess: 'COMPANY_GRANT_COMPANY_ACCESS',
	grantCompanyAccessSuccess: 'COMPANY_GRANT_COMPANY_ACCESS_SUCCESS',
	grantCompanyAccessFail: 'COMPANY_GRANT_COMPANY_ACCESS_FAIL',
	getLocations: 'COMPANY_GET_LOCATIONS',
	getLocationsSuccess: 'COMPANY_GET_LOCATIONS_SUCCESS',
	getLocationsFail: 'COMPANY_GET_LOCATIONS_FAIL',
	getCompanyById: 'COMPANY_GET_COMPANY_BY_ID',
	getCompanyByIdSuccess: 'COMPANY_GET_COMPANY_BY_ID_SUCCESS',
	getCompanyByIdFail: 'COMPANY_GET_COMPANY_BY_ID_FAIL',
	createLocation: 'COMPANY_CREATE_LOCATION',
	createLocationSuccess: 'COMPANY_CREATE_LOCATION_SUCCESS',
	createLocationFail: 'COMPANY_CREATE_LOCATION_FAIL',
	editLocation: 'COMPANY_EDIT_LOCATION',
	editLocationSuccess: 'COMPANY_EDIT_LOCATION_SUCCESS',
	editLocationFail: 'COMPANY_EDIT_LOCATION_FAIL',
	editCompanyDetails: 'COMPANY_EDIT_DETAILS',
	editCompanyDetailsSuccess: 'COMPANY_EDIT_DETAILS_SUCCESS',
	editCompanyDetailsFail: 'COMPANY_EDIT_DETAILS_FAIL',
	getCompanyContacts: 'COMPANY_GET_COMPANY_CONTACTS',
	getCompanyContactsSuccess: 'COMPANY_GET_COMPANY_CONTACTS_SUCCESS',
	getCompanyContactsFail: 'COMPANY_GET_COMPANY_CONTACTS_FAIL',
	clearContactsAndLocations: 'COMPANY_CLEAR_CONTACTS_LOCATIONS'
}
// location
export const locationTypes = {
	getSelectedLocation: 'LOCATION_GET_SELECTED_LOCATION',
	resetState: 'LOCATION_RESET_STATE'
}

// serviceRequest
export const serviceRequestTypes = {
	getSelectedRequest: 'SERVICE_REQUEST_GET_SELECTED_REQUEST',
	resetState: 'SERVICE_REQUEST_RESET_STATE',
	initRequest: 'SERVICE_REQUEST_INIT_REQUEST',

	logHour: 'SERVICE_REQUEST_LOG_HOUR',
	logHourSuccess: 'SERVICE_REQUEST_LOG_HOUR_SUCCESS',
	logHourFail: 'SERVICE_REQUEST_LOG_HOUR_FAIL',
	getResourceDetail: 'SERVICE_REQUEST_GET_RESOURCE_DETAIL',
	getResourceDetailSuccess: 'SERVICE_REQUEST_GET_RESOURCE_DETAIL_SUCCESS',
	getResourceDetailFail: 'SERVICE_REQUEST_GET_RESOURCE_DETAIL_FAIL',
	getLoggedTime: 'SERVICE_REQUEST_GET_LOGGED_TIME',
	getLoggedTimeSuccess: 'SERVICE_REQUEST_GET_LOGGED_TIME_SUCCESS',
	getLoggedTimeFail: 'SERVICE_REQUEST_GET_LOGGED_TIME_FAIL',
	getAll: 'SERVICE_REQUEST_GET_ALL',
	getAllSuccess: 'SERVICE_REQUEST_GET_ALL_SUCCESS',
	getAllFail: 'SERVICE_REQUEST_GET_ALL_FAIL',
	createOne: 'SERVICE_REQUEST_CREATE_ONE',
	createOneSuccess: 'SERVICE_REQUEST_CREATE_ONE_SUCCESS',
	createOneFail: 'SERVICE_REQUEST_CREATE_ONE_FAIL',
	getOne: 'SERVICE_REQUEST_GET_ONE',
	getOneSuccess: 'SERVICE_REQUEST_GET_ONE_SUCCESS',
	getOneFail: 'SERVICE_REQUEST_GET_ONE_FAIL',
	updateOne: 'SERVICE_REQUEST_UPDATE_ONE',
	updateOneSuccess: 'SERVICE_REQUEST_UPDATE_ONE_SUCCESS',
	updateOneFail: 'SERVICE_REQUEST_UPDATE_ONE_FAIL',
	addAttachment: 'SERVICE_REQUEST_ADD_ATTACHMENT',
	addAttachmentSuccess: 'SERVICE_REQUEST_ADD_ATTACHMENT_SUCCESS',
	addAttachmentFail: 'SERVICE_REQUEST_ADD_ATTACHMENT_FAIL',
	getTicketAttachments: 'SERVICE_REQUEST_GET_ATTACHMENTS',
	getTicketAttachmentsSuccess: 'SERVICE_REQUEST_GET_ATTACHMENTS_SUCCESS',
	getTicketAttachmentsFail: 'SERVICE_REQUEST_GET_ATTACHMENTS_FAIL',
	deleteAttachment: 'SERVICE_REQUEST_DELETE_ATTACHMENT',
	deleteAttachmentSuccess: 'SERVICE_REQUEST_DELETE_ATTACHMENT_SUCCESS',
	deleteAttachmentFail: 'SERVICE_REQUEST_DELETE_ATTACHMENT_FAIL',
	changeItemsPerPage: 'SERVICE_REQUEST_CHANGE_ITEMS_PER_PAGE',
	clearTickets: 'SERVICE_REQUEST_CLEAR_TICKETS'
}

// device
export const deviceTypes = {
	getAll: 'DEVICE_GET_ALL',
	getAllSuccess: 'DEVICE_GET_ALL_SUCCESS',
	getAllFail: 'DEVICE_GET_ALL_FAIL',
	getAllIds: 'DEVICE_GET_ALL_IDS',
	getAllIdsSuccess: 'DEVICE_GET_ALL_IDS_SUCCESS',
	getAllIdsFail: 'DEVICE_GET_ALL_IDS_FAIL',
	changeItemsPerPage: 'DEVICE_CHANGE_ITEMS_PER_PAGE',
	clearDevices: 'DEVICE_CLEAR_DEVICES'
}
// order
export const orderTypes = {
	getAll: 'ORDER_GET_ALL',
	getAllSuccess: 'ORDER_GET_ALL_SUCCESS',
	getAllFail: 'ORDER_GET_ALL_FAIL',
	getOrder: 'ORDER_GET_ORDER',
	resetOrder: 'ORDER_RESEST_ORDER'
}
