/**
 * Device action creator
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2021
 */
import { deviceTypes as types } from './types'

const getAll = (companyId, isBackground) => ({
	type: types.getAll,
	payload: {
		request: {
			method: 'GET',
			url: `/ncentral/customerDevices/${companyId}`,
		}
	},
	isBackground
})

const changeItemsPerPage = amount => {
	return {
		type: types.changeItemsPerPage,
		amount
	}
}
const clearDevices = () => {
	return {
		type: types.clearDevices
	}
}

export default {
	getAll,
	changeItemsPerPage,
	clearDevices
}
