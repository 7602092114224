/**
 * Location action creators
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { locationTypes as types } from './types'

const getSelectedLocation = location => {
	return { type: types.getSelectedLocation, location }
}
const resetState = location => {
	return { type: types.resetState, location }
}
export default {
	getSelectedLocation,
	resetState
}
