/**
 *
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
// WORK INSTRUCTIONS:
// - Please do not remove the above header. It protects the IPR intrests of our company.
// - Please replace the author name with your own full real name.

import { hot } from 'react-hot-loader/root'
import React, { Component, lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { userModule } from 'modules'
import { useSelector, useDispatch } from 'react-redux'

import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { routerPath, AULA_ROLES } from 'constants'
// Code splitting and lazy loading
const Dashboard = lazy(() =>
	import(/* webpackChunkName: "Home" */ 'pages/Dashboard')
)

const AdminDashboard = lazy(() =>
	import(/* webpackChunkName: "AdminDashboard" */ 'pages/AdminDashboard')
)
const AdminReport = lazy(() =>
	import(/* webpackChunkName: "AdminReport" */ 'pages/AdminReport')
)
const AdminCompanyManagement = lazy(() =>
	import(
		/* webpackChunkName: "AdminCompanyManagement" */ 'pages/AdminCompanyManagement'
	)
)
const AdminCompanies = lazy(() =>
	import(/* webpackChunkName: "AdminCompanies" */ 'pages/AdminCompanies')
)
const Login = lazy(() => import(/* webpackChunkName: "Login" */ 'pages/Login'))

const ForgetPassword = lazy(() =>
	import(/* webpackChunkName: "ForgetPassword" */ 'pages/ForgetPassword')
)
const ResetPassword = lazy(() =>
	import(/* webpackChunkName: "ResetPassword" */ 'pages/ResetPassword')
)
const UserManagement = lazy(() =>
	import(/* webpackChunkName: "UserManagement" */ 'pages/UserManagement')
)
const UserForms = lazy(() =>
	import(/* webpackChunkName: "UserForms" */ 'pages/UserForms')
)
const ServiceRequests = lazy(() =>
	import(/* webpackChunkName: "ServiceRequests" */ 'pages/ServiceRequests')
)
const ContactRequests = lazy(() =>
	import(/* webpackChunkName: "ContactRequests" */ 'pages/ContactRequests')
)
const Training = lazy(() =>
	import(/* webpackChunkName: "Training" */ 'pages/Training')
)
const Devices = lazy(() =>
	import(/* webpackChunkName: "Devices" */ 'pages/Devices')
)
const Orders = lazy(() =>
	import(/* webpackChunkName: "Orders" */ 'pages/Orders')
)
const OfflineMode = lazy(() =>
	import(/* webpackChunkName: "OfflineMode" */ 'pages/OfflineMode')
)
// Components
import { Offline, Online } from 'react-detect-offline'
import CoreLayout from './shared/components/layout/CoreLayout'
import ProtectedRoute from './shared/components/auth/ProtectedRoute'
import Loading from 'shared/components/LoadingIndicator'

import { userSelectors } from 'reducers'
class App extends Component {
	render() {
		return (
			<Suspense fallback={<Loading />}>
				<Helmet titleTemplate='%s' defaultTitle='Aula ERP'>
					<meta
						name='description'
						content='A React.js Boilerplate application'
					/>
				</Helmet>
				{/* <Offline>
					<OfflineMode />
				</Offline> */}
				{/* <Online> */}
				<Router>
					{this.props.isAuthenticated ? (
						<AuthenticatedRoutes />
					) : (
						<UnauthenticatedRoutes />
					)}
				</Router>
				{/* </Online> */}
			</Suspense>
		)
	}
}

const AuthenticatedRoutes = () => {
	const userRole = useSelector(state => userSelectors.getRole(state.user))
	const redirectRoute = userModule.getRedirectAuthenticatedRoute(userRole)

	return (
		<CoreLayout>
			<Switch>
				<ProtectedRoute
					exact
					path={routerPath.dashboard}
					component={Dashboard}
				/>
				<ProtectedRoute
					path={routerPath.userManagement}
					component={UserManagement}
				/>
				<ProtectedRoute
					exact
					path={[routerPath.serviceRequests, routerPath.serviceRequestsEdit]}
					component={ServiceRequests}
				/>
				<ProtectedRoute path={routerPath.devices} component={Devices} />
				<ProtectedRoute path={routerPath.orders} component={Orders} />
				<ProtectedRoute
					path={routerPath.adminDashboard}
					component={AdminDashboard}
					protectedRoles={[AULA_ROLES.super_admin]}
				/>
				<ProtectedRoute
					path={routerPath.adminReport}
					component={AdminReport}
					protectedRoles={[AULA_ROLES.super_admin]}
				/>
				<ProtectedRoute
					path={[
						routerPath.adminCompanyManagementDetail,
						routerPath.adminCompanyManagement
					]}
					component={AdminCompanyManagement}
					protectedRoles={[AULA_ROLES.super_admin]}
				/>

				<ProtectedRoute
					protectedRoles={[AULA_ROLES.super_admin]}
					path={routerPath.adminCompanies}
					component={AdminCompanies}
				/>

				{/* <ProtectedRoute
					path={routerPath.contactRequests}
					component={ContactRequests}
				/>

				<ProtectedRoute path={routerPath.training} component={Training} /> */}
				<Redirect to={redirectRoute} />
			</Switch>
		</CoreLayout>
	)
}
const UnauthenticatedRoutes = () => {
	return (
		<Switch>
			<Route exact path={routerPath.login} component={Login} />
			<Route
				exact
				path={routerPath.forgetPassword}
				component={ForgetPassword}
			/>
			<Route exact path={routerPath.resetPassword} component={ResetPassword} />
			<Redirect to={routerPath.login} />
		</Switch>
	)
}
const mapStateToProps = state => ({
	isAuthenticated: userSelectors.isAuthenticated(state.user)
})
export default hot(connect(mapStateToProps)(App))
