/**
 * API Status module
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
export default {
	idle: 'idle',
	pending: 'pending',
	resolved: 'resolved',
	rejected: 'rejected'
}
