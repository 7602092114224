/**
 * Order action creator
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2021
 */
import { orderTypes as types } from './types'

const getAll = autotaskId => ({
	type: types.getAll,
	payload: {
		request: {
			method: 'GET',
			url: `/ecommerce/orders/companyAutotaskId/${autotaskId}`
		}
	}
})
const getOrder = order => ({
	type: types.getOrder,
	order
})
const resetOrder = () => ({
	type: types.resetOrder
})
export default {
	getAll,
	getOrder,
	resetOrder
}
