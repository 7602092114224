/**
 * Device reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { deviceTypes as types } from '../actions/types'
import { devices } from '../data'
import apiStatus from 'modules/status'
import { DEFAULT_USER_AMOUNT } from 'constants'
import { deviceModule } from 'modules'
import device from '../modules/device'
import { useTranslation } from 'react-i18next'

const initialState = {
	devices: [],
	itemsPerPage: DEFAULT_USER_AMOUNT,
	status: apiStatus.idle,
	error: null
}
const normalizeLoggedInUser = name => {
	if (name) {
		const newNameArray1 = name.split('\\')
		let normalizedName = newNameArray1[newNameArray1.length - 1]

		if (!normalizedName.includes('.')) {
			return normalizedName
		}
		const newNameArray2 = normalizedName.split('.')
		return newNameArray2.reduce((acc, item) => acc.concat(`${item} `), '')
	}
	return name
}
const normalizeDevices = (data) => {

	return data.map(item => {
		const normalizedDevice = deviceModule.normalizeDevice(item)
		//const newUserName = normalizeLoggedInUser(foundDeviceObj.lastloggedinuser)
		//normalizedDevice.lastloggedinuser = newUserName
		return normalizedDevice
	})
}

export const devicesReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.changeItemsPerPage:
			return { ...state, itemsPerPage: action.amount }

		case types.getAll:
			if (action.isBackground) {
				return { ...state }
			}
			return { ...state, status: apiStatus.pending }
		case types.getAllSuccess:
			return {
				...state,
				status: apiStatus.resolved,
				devices: normalizeDevices(action.payload.data)
			}
		case types.getAllFail:
			return { ...state, status: apiStatus.reject }
		case types.clearDevices:
			return { ...state, devices: [] }
		default:
			return state
	}
}

export const devicesSelectors = {
	getDevices: (state = initialState) => state.devices,
	getApiStatus: (state = initialState) => state.status,
	getItemsPerPage: (state = initialState) => state.itemsPerPage
}
