import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'shared/components/Button'

const LocalizationButton = ({lang}) => {
    const { t, i18n } = useTranslation()

    const buttonTextBack = (
		<div className='button-text__content'>
			{lang}
		</div>
	)
	const changeLocalization = () => {
        i18n.changeLanguage(lang)
    }

    return (
		<div className='localization-button'>
			<Button text={buttonTextBack} onClick={changeLocalization} style={{height: '30px', width: '24px'}}/>
		</div>
	)
}

export default LocalizationButton