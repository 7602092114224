import React, { forwardRef } from 'react'

const Button = forwardRef(
	(
		{
			text,
			onClick,
			className,
			textClassName,
			buttonProps,
			containerClassName,
			iconClassName,
			iconPosition,
			type = 'button',
			disabled,
			children,
			...props
		},
		ref
	) => {
		const buttonTextClassName = textClassName
			? [textClassName].concat(['button-text']).join(' ')
			: 'button-text'
		const buttonContainerClassName = containerClassName
			? ['button-container'].concat([containerClassName]).join(' ')
			: 'button-container'
		const renderIcon = () => <i className={`${iconClassName} icon`} />
		return (
			<div className={buttonContainerClassName} ref={ref} {...props}>
				<button
					onClick={onClick}
					className={className}
					type={type}
					{...buttonProps}
					disabled={disabled}
				>
					<div
						className={`${buttonTextClassName} ${
							iconPosition ? `icon-${iconPosition}` : ''
						} ${iconClassName && !text ? 'button-icon' : ''}`}
					>
						{children ? (
							children
						) : (
							<React.Fragment>
								{iconClassName && iconPosition === 'start' && renderIcon()}
								{text}
								{iconClassName && iconPosition === 'end' && renderIcon()}
							</React.Fragment>
						)}
					</div>
				</button>
			</div>
		)
	}
)

export default Button
