/**
 * Locations reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { loginTypes, userTypes } from '../actions/types'
import { locations } from '../data'

const initialState = {
	locations: [],
	error: null
}

export const locationsReducer = (state = initialState, action) => {
	switch (action.type) {
		default:
			return state
	}
}

export const locationsSelectors = {
	getLocations: (state = initialState) => locations
}
