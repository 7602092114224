/**
 * Users reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import { userTypes as types } from '../actions/types'
import { userModule } from './../modules'
import apiStatus from 'modules/status'
import { DEFAULT_USER_AMOUNT } from 'constants'
const initialState = {
	users: [],
	selectedUser: null,
	itemsPerPage: DEFAULT_USER_AMOUNT,
	usersStatus: apiStatus.idle,
	userStatus: apiStatus.idle,
	prevPageUrl: null,
	nextPageUrl: null,
	error: null
}
const normalizeUsers = data => {
	if (!data || data.length === 0) {
		return []
	}
	return data.map(userModule.normalizeUser)
}
export const usersReducer = (state = initialState, action) => {
	let updatedUser
	switch (action.type) {
		case types.changeItemsPerPage:
			return { ...state, itemsPerPage: action.amount }
		case types.getCompanyUsers:
			if (action.isBackground) {
				return { ...state }
			}
			return { ...state, usersStatus: apiStatus.pending }
		case types.getCompanyUsersSuccess:
			return {
				...state,
				users: normalizeUsers(action.payload.data.items),
				prevPageUrl: action.payload.data.pageDetails.prevPageUrl,
				nextPageUrl: action.payload.data.pageDetails.nextPageUrl,
				usersStatus: apiStatus.resolved
			}
		case types.getCompanyUsersFail:
			return { ...state, usersStatus: apiStatus.reject }
		case types.fetchUserById:
			return { ...state }
		case types.fetchUserByIdSuccess:
			return {
				...state,
				selectedUser: userModule.normalizeUser(action.payload.data)
			}
		case types.fetchUserByIdFail:
			return { ...state }
		case types.updateUser:
			return {
				...state,
				userStatus: apiStatus.pending
			}
		case types.updateUserSuccess:
			updatedUser = userModule.normalizeUser(action.payload.data)
			return {
				...state,
				selectedUser: {
					...state.selectedUser,
					aulaRole: updatedUser.aulaRole,
					autotaskRole: updatedUser.autotaskRole,
					ecommerceRole: updatedUser.ecommerceRole
				},
				userStatus: apiStatus.resolved
			}
		case types.updateUserFail:
			return { ...state, userStatus: apiStatus.reject }
		case types.getSelectedUser:
			return { ...state, selectedUser: action.user }
		case types.clearUsers:
			return { ...state, users: [] }
		default:
			return state
	}
}

export const usersSelectors = {
	getUsers: (state = initialState) => state.users,
	getSelectedUser: (state = initialState) => state.selectedUser,
	getUsersApiStatus: (state = initialState) => state.usersStatus,
	getUserApiStatus: (state = initialState) => state.userStatus,
	getPrevPageUrl: (state = initialState) => state.prevPageUrl,
	getNextPageUrl: (state = initialState) => state.nextPageUrl,
	getItemsPerPage: (state = initialState) => state.itemsPerPage
}
