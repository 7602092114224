/**
 * ServiceRequest action creators
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { serviceRequestTypes as types } from './types'
import { LIST_RECORDS } from 'constants'
import moment from 'moment'
const getSelectedRequest = request => {
	return { type: types.getSelectedRequest, request }
}
const resetState = () => {
	return { type: types.resetState }
}
const getAll = (companyId, { createDate = null, inputDate }, isBackground) => {
	let url, params
	if (createDate) {
		url = `/ticket/createDate/company/${companyId}`
		params = { createDate }
	} else {
		url = `/ticket/company/${companyId}`
		params = { inputDate }
	}
	return {
		type: types.getAll,
		payload: {
			request: {
				method: 'GET',
				url,
				params
			}
		},
		isBackground
	}
}

const initRequest = request => {
	return {
		type: types.initRequest,
		request
	}
}
const createOne = data => {
	return {
		type: types.createOne,
		payload: {
			request: {
				method: 'POST',
				url: `/ticket`,
				data
			}
		}
	}
}
const logHour = data => {
	return {
		type: types.logHour,
		payload: {
			request: {
				method: 'POST',
				url: `/ticket/timeEntry`,
				data
			}
		}
	}
}
const getLoggedTime = (companyId, ticketAutotaskId) => {
	return {
		type: types.getLoggedTime,
		payload: {
			request: {
				method: 'GET',
				url: `/ticket/companyId/${companyId}/ticketAutotaskId/${ticketAutotaskId}/timeEntries`,
			}
		}
	}
}
const getOne = (companyId, ticketAutotaskId) => {
	return {
		type: types.getOne,
		payload: {
			request: {
				method: 'GET',
				url: `/ticket/companyId/${companyId}/ticketAutotaskId/${ticketAutotaskId}`
			}
		}
	}
}
const updateOne = (autotaskTicketId, data) => {
	return {
		type: types.updateOne,
		payload: {
			request: {
				method: 'PATCH',
				url: `/ticket/${autotaskTicketId}`,
				data
			}
		}
	}
}
const getResourceDetail = () => {
	return {
		type: types.getResourceDetail,
		payload: {
			request: {
				method: 'GET',
				url: `/ticket/resource`
			}
		}
	}
}
const superadminUpdateOne = (autotaskTicketId, data) => {
	return {
		type: types.updateOne,
		payload: {
			request: {
				method: 'PATCH',
				url: `/ticket/superAdmin/${autotaskTicketId}`,
				data
			}
		}
	}
}
const changeItemsPerPage = amount => {
	return {
		type: types.changeItemsPerPage,
		amount
	}
}

const addAttachment = (ticketAutotaskId, data) => {
	return {
		type: types.addAttachment,
		payload: {
			request: {
				method: 'POST',
				url: `/ticket/attachment/${ticketAutotaskId}`,
				data
			}
		}
	}
}
const getTicketAttachments = (companyId, ticketAutotaskId) => {
	return {
		type: types.getTicketAttachments,
		payload: {
			request: {
				method: 'GET',
				url: `/ticket/attachment/companyId/${companyId}/ticketAutotaskId/${ticketAutotaskId}`
			}
		}
	}
}
const deleteAttachment = (ticketAutotaskId, ticketAttachmentAutotaskId) => {
	return {
		type: types.deleteAttachment,
		payload: {
			request: {
				method: 'DELETE',
				url: `/ticket/attachment/ticketAutotaskId/${ticketAutotaskId}/ticketAttachmentAutotaskId/${ticketAttachmentAutotaskId}`
			}
		}
	}
}
const clearTickets = () => {
	return {
		type: types.clearTickets
	}
}
export default {
	getSelectedRequest,
	resetState,
	getAll,
	initRequest,
	createOne,
	changeItemsPerPage,
	updateOne,
	addAttachment,
	getTicketAttachments,
	deleteAttachment,
	getOne,
	clearTickets,
	logHour,
	superadminUpdateOne,
	getResourceDetail,
	getLoggedTime
}
