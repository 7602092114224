/**
 * Device reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { orderTypes as types } from '../actions/types'
import apiStatus from 'modules/status'
import { DEFAULT_USER_AMOUNT, ORDER_STATUS_OPTIONS } from 'constants'
import { languageUtils } from 'utils'
import i18next from 'i18next'

const initialState = {
	orders: [],
	itemsPerPage: DEFAULT_USER_AMOUNT,
	status: apiStatus.idle,
	error: null
}
const normalizedOrders = data => {
	if (!data || !data.length) return []
	return data.map(item => {
		const orderNumber = item['OrderNumber']['SupplierOrderNumber']
		const orderNumberCheck = item['OrderNumber']['SupplierOrderNumber']
		// const orderDate = item['ns0:OrderResponse']['OrderHeader']['POIssuedDate']
		const orderDate = item['SaveDate']
		const deliveryAddress = ''

		const orderCount =
			item['ListofOrderDetails']['OrderLine']['Quantity']['QtyConfirmed']
		const sum =
			Number(
				item['ListofOrderDetails']['OrderLine']['Price']['UnitPrice']['#text']
			) * orderCount
		const status = item['OrderStatus']
		const products = [item['ListofOrderDetails']['OrderLine']]
		const t = i18next.t.bind(i18next)
		const foundStatus = languageUtils
			.translateOptions(ORDER_STATUS_OPTIONS, t)
			.find(item => item.value == status)
		return {
			orderNumber,
			orderDate,
			orderCount,
			sum,
			statusValue: status,
			status: foundStatus ? foundStatus.label : '',
			lastChanged: orderDate,
			orderNumberCheck,
			products,
			deliveryAddress
		}
	})
}
export const ordersReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.getAll:
			return { ...state, status: apiStatus.pending }
		case types.getAllSuccess:
			return {
				...state,
				status: apiStatus.resolved,
				orders: normalizedOrders(
					action.payload.data['BacklogResponse']['ListofBacklogOrders'][
						'BacklogOrder'
					]
				)
			}
		case types.getAllFail:
			return { ...state, status: apiStatus.reject }

		default:
			return state
	}
}

export const ordersSelectors = {
	getAll: (state = initialState) => state.orders,
	getItemsPerPage: (state = initialState) => state.itemsPerPage,

	getApiStatus: (state = initialState) => state.status
}
