/**
 * Utils for language
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */

const translateOptions = (options, t) => {
	if (!options || options.lengh === 0) {
		return []
	}
	return options.map(item => ({ ...item, label: t(`${item.label}`) }))
}
export default { translateOptions }
