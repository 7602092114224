/**
 * Location modules helper
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */

const locationModel = {
	address1: '',
	address2: '',
	city: '',
	countryID: 76,
	isActive: true,
	isPrimary: true,
	name: 'New Location',
	phone: '',
	postalCode: '',
	roundtripDistance: 0
}

export default { locationModel }
