/**
 * User modules helper
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { store } from './../store'
// actions
import { userActions } from 'actions'
// constant
import { AULA_ROLES, AUTOTASK_ROLES, PERMISSIONS, routerPath } from 'constants'
// reducers
import { usersSelectors, companySelectors } from 'reducers'
import apiStatus from 'modules/status'
import { COUNTRY_DATA } from 'data'
export const normalizeUser = item => {
	const { firstName, lastName, companyLocation } = item
	const foundCountry = COUNTRY_DATA.find(
		country => country.id === item.countryID
	)
	return {
		...item,
		name: firstName + ' ' + lastName,
		countryLabel: foundCountry ? foundCountry.displayName : 'No country',
		companyLocationName: companyLocation ? companyLocation.name : ''
	}
}
export const getRedirectAuthenticatedRoute = role => {
	switch (role) {
		case AULA_ROLES.super_admin:
			return routerPath.adminDashboard
		default:
			return routerPath.dashboard
	}
}
/**
 * users hook
 * Fetch company users from server and save data to the state
 * once the request is resolved
 *
 * @return {Array} a list of users fetched from server
 */

const useUsers = companyId => {
	const dispatch = useDispatch()

	const fetchedUsers = useSelector(state =>
		usersSelectors.getUsers(state.users)
	)
	const itemsPerPage = useSelector(state =>
		usersSelectors.getItemsPerPage(state.users)
	)
	const prevPageUrl = useSelector(state =>
		usersSelectors.getPrevPageUrl(state.users)
	)
	const nextPageUrl = useSelector(state =>
		usersSelectors.getNextPageUrl(state.users)
	)
	const status = useSelector(state =>
		usersSelectors.getUsersApiStatus(state.users)
	)
	const company = useSelector(state =>
		companySelectors.getCompany(state.company)
	)
	const [users, setUsers] = React.useState(fetchedUsers)

	React.useEffect(() => {
		dispatch(
			userActions.getCompanyUsers(
				companyId,
				{ isRequiredOnly: true },
				!!(fetchedUsers && fetchedUsers.length)
			)
		)
	}, [])
	React.useEffect(() => {
		if (status === apiStatus.resolved) {
			setUsers(fetchedUsers)
		}
	}, [status, fetchedUsers])

	return {
		users,
		status,
		prevPageUrl,
		nextPageUrl,
		itemsPerPage
	}
}
const getUserRole = () => {
	const user = store.getState().user.user
	if (user && user.aulaRole) {
		return user.aulaRole
	}
	return ''
}
/**
 * User permission
 * Check permission of user to click a button,...
 *
 *
 */

const isRoleAllowed = (name, role) => {
	let checkRole = role ? role : getUserRole()
	const adminRoles = [AULA_ROLES.company_admin, AULA_ROLES.super_admin]
	if (adminRoles.some(role => role == checkRole)) {
		return true
	}
	if (PERMISSIONS.hasOwnProperty(checkRole)) {
		return PERMISSIONS[checkRole][name]
	}
	return false
}
/**
 * Filter aula role options by user role
 *
 * @param {String} userRole role of current user
 * @param {Array} data all  options for role
 *
 * @return {Array} available options depends on role
 */

const filterAulaRoleByUserRole = (userRole, data) => {
	if (userRole === AULA_ROLES.super_admin) return data
	return data.filter(item => item.value !== AULA_ROLES.super_admin)
}

export default {
	filterAulaRoleByUserRole,
	normalizeUser,
	filterAulaRoleByUserRole,
	useUsers,
	isRoleAllowed,
	getRedirectAuthenticatedRoute
}
