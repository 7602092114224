/**
 * Login action creator
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { loginTypes as types } from './types'

export const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

export const login = data => {
	return {
		type: types.login,
		payload: {
			request: {
				method: 'POST',
				url: `/auth/login`,
				data
			}
		}
	}
}
export const forgetPassword = params => {
	return {
		type: types.forgetPassword,
		payload: {
			request: {
				method: 'GET',
				url: `/auth/password/forgot`,
				params
			}
		}
	}
}
export const resetPassword = (token, data) => {
	return {
		type: types.resetPassword,
		payload: {
			request: {
				method: 'POST',
				url: `/auth/password/change`,
				headers: {
					authorization: `Bearer ${token}`
				},
				data
			}
		}
	}
}
export const googleLogin = (userId, token, refreshToken, companyId) => {
	return {
		type: types.googleLogin,
		payload: {
			request: {
				method: 'GET',
				url: `/users/${userId}`,
				headers: {
					authorization: `Bearer ${token}`
				}
			}
		},
		token,
		refreshToken,
		companyId
	}
}
export const localLogout = () => {
	return {
		type: types.localLogout
	}
}
export const logout = refreshToken => {
	return {
		type: types.logout,
		payload: {
			request: {
				method: 'GET',
				url: `/auth/signout`,
				params: {
					refresh: refreshToken
				}
			}
		}
	}
}
