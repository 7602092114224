/**
 * Utility hooks
 *
 * @author Vinh Le <lethanhvinh95@gmail.com>
 *
 */
import React from 'react'

/**
 * Use previous hook
 *
 * @param {any} value value that we want to keep track of
 * @return {any}
 */
export const usePrevious = value => {
	const ref = React.useRef()

	React.useEffect(() => {
		ref.current = value
	}, [value])

	return ref.current
}
