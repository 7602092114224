/**
 * Dummy data
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */


const company = {
	id: 0,
	additionalAddressInformation: '',
	address1: 'Linnoitustie 4 A',
	address2: null,
	alternatePhone1: '',
	alternatePhone2: '',
	apiVendorID: null,
	assetValue: null,
	billToCompanyLocationID: null,
	billToAdditionalAddressInformation: '',
	billingAddress1: 'Linnoitustie 4 A',
	billingAddress2: '',
	billToAddressToUse: 1,
	billToAttention: '',
	billToCity: 'ESPOO',
	billToCountryID: null,
	billToState: '',
	billToZipCode: '02600',
	city: 'ESPOO',
	classification: null,
	companyName: 'VM IT OY',
	companyNumber: '0',
	companyType: 1,
	competitorID: null,
	countryID: null,
	createDate: '2002-05-16T10:56:33.81Z',
	createdByResourceID: 4,
	currencyID: 2,
	fax: '',
	impersonatorCreatorResourceID: null,
	invoiceEmailMessageID: 1,
	invoiceMethod: null,
	invoiceNonContractItemsToParentCompany: null,
	invoiceTemplateID: 102,
	isActive: true,
	isClientPortalActive: true,
	isEnabledForComanaged: false,
	isTaskFireActive: false,
	isTaxExempt: false,
	lastActivityDate: '2015-05-18T10:54:08Z',
	lastTrackedModifiedDateTime: '2002-05-16T10:56:33.81Z',
	marketSegmentID: null,
	ownerResourceID: 4,
	parentCompanyID: null,
	phone: '+358 75 755 0300',
	postalCode: '02600',
	quoteEmailMessageID: 1,
	quoteTemplateID: 1,
	sicCode: '',
	state: '',
	stockMarket: '',
	stockSymbol: '',
	surveyCompanyRating: null,
	taxID: '',
	taxRegionID: null,
	territoryID: null,
	webAddress: 'www.vmit.fi',
	userDefinedFields: [
		{
			name: 'Google käytössä',
			value: false
		},
		{
			name: 'Henkilöstömäärä',
			value: 142
		},
		{
			name: 'Lead Source',
			value: null
		},
		{
			name: 'N-central Customer ID',
			value: '104'
		},
		{
			name: 'N-Central ID',
			value: 123
		},
		{
			name: 'Number of Employees',
			value: 12
		},
		{
			name: 'Office 365 käytössä',
			value: true
		}
	]
}
const tickets = [
	{
		id: 125413,
		apiVendorID: null,
		assignedResourceID: null,
		assignedResourceRoleID: null,
		billingCodeID: null,
		changeApprovalBoard: null,
		changeApprovalStatus: null,
		changeApprovalType: null,
		changeInfoField1: '',
		changeInfoField2: '',
		changeInfoField3: '',
		changeInfoField4: '',
		changeInfoField5: '',
		companyID: 29683968,
		companyLocationID: 273,
		completedByResourceID: null,
		completedDate: '2020-12-08T12:18:16Z',
		configurationItemID: null,
		contactID: 'John Doe',
		contractID: null,
		contractServiceBundleID: null,
		contractServiceID: null,
		createDate: '2020-12-08T12:18:15.793Z',
		createdByContactID: null,
		creatorResourceID: 30683953,
		creatorType: 1,
		currentServiceThermometerRating: null,
		description: null,
		dueDateTime: '2020-12-11T12:18:00Z',
		estimatedHours: null,
		externalID: '',
		firstResponseAssignedResourceID: null,
		firstResponseDateTime: '2020-12-08T12:18:15.793Z',
		firstResponseDueDateTime: '2020-12-09T12:18:15.793Z',
		firstResponseInitiatingResourceID: 30683953,
		hoursToBeScheduled: 0.0,
		impersonatorCreatorResourceID: null,
		issueType: 1,
		lastActivityDate: '2020-12-08T12:18:32.417Z',
		lastActivityPersonType: 1,
		lastActivityResourceID: 4,
		lastCustomerNotificationDateTime: null,
		lastCustomerVisibleActivityDateTime: null,
		lastTrackedModificationDateTime: '2020-12-08T12:18:32.417Z',
		monitorID: null,
		monitorTypeID: null,
		opportunityID: null,
		organizationalLevelAssociationID: null,
		previousServiceThermometerRating: null,
		priority: 1,
		problemTicketId: null,
		projectID: null,
		purchaseOrderNumber: '',
		queueID: 5,
		resolution: '',
		resolutionPlanDateTime: '2020-12-08T12:18:15.793Z',
		resolutionPlanDueDateTime: null,
		resolvedDateTime: '2020-12-08T12:18:15.793Z',
		resolvedDueDateTime: '2020-12-11T12:18:15.793Z',
		rmaStatus: null,
		rmaType: null,
		rmmAlertID: null,
		serviceLevelAgreementHasBeenMet: null,
		serviceLevelAgreementID: 1,
		serviceLevelAgreementPausedNextEventHours: null,
		serviceThermometerTemperature: null,
		source: 'www.google.com',
		status: 1,
		subIssueType: 2,
		ticketCategory: 3,
		ticketNumber: 'T20201208.0047',
		ticketType: 1,
		title: 'Create task',
		userDefinedFields: []
	},
	{
		id: 125414,
		apiVendorID: null,
		assignedResourceID: null,
		assignedResourceRoleID: null,
		billingCodeID: null,
		changeApprovalBoard: null,
		changeApprovalStatus: null,
		changeApprovalType: null,
		changeInfoField1: '',
		changeInfoField2: '',
		changeInfoField3: '',
		changeInfoField4: '',
		changeInfoField5: '',
		companyID: 29683968,
		companyLocationID: 273,
		completedByResourceID: null,
		completedDate: '2020-12-08T12:18:54.08Z',
		configurationItemID: null,
		contactID: 'Jack',
		contractID: null,
		contractServiceBundleID: null,
		contractServiceID: null,
		createDate: '2020-12-08T12:18:53.89Z',
		createdByContactID: null,
		creatorResourceID: 30683953,
		creatorType: 1,
		currentServiceThermometerRating: null,
		description: null,
		dueDateTime: '2020-12-11T12:18:00Z',
		estimatedHours: null,
		externalID: '',
		firstResponseAssignedResourceID: null,
		firstResponseDateTime: '2020-12-08T12:18:53.89Z',
		firstResponseDueDateTime: '2020-12-09T12:18:53.89Z',
		firstResponseInitiatingResourceID: 30683953,
		hoursToBeScheduled: 0.0,
		impersonatorCreatorResourceID: null,
		issueType: null,
		lastActivityDate: '2020-12-08T12:19:15.023Z',
		lastActivityPersonType: 1,
		lastActivityResourceID: 4,
		lastCustomerNotificationDateTime: null,
		lastCustomerVisibleActivityDateTime: null,
		lastTrackedModificationDateTime: '2020-12-08T12:19:15.023Z',
		monitorID: null,
		monitorTypeID: null,
		opportunityID: null,
		organizationalLevelAssociationID: null,
		previousServiceThermometerRating: null,
		priority: 2,
		problemTicketId: null,
		projectID: null,
		purchaseOrderNumber: '',
		queueID: 5,
		resolution: '',
		resolutionPlanDateTime: '2020-12-08T12:18:53.89Z',
		resolutionPlanDueDateTime: null,
		resolvedDateTime: '2020-12-08T12:18:53.89Z',
		resolvedDueDateTime: '2020-12-11T12:18:53.89Z',
		rmaStatus: null,
		rmaType: null,
		rmmAlertID: null,
		serviceLevelAgreementHasBeenMet: null,
		serviceLevelAgreementID: 1,
		serviceLevelAgreementPausedNextEventHours: null,
		serviceThermometerTemperature: null,
		source: null,
		status: 5,
		subIssueType: null,
		ticketCategory: 3,
		ticketNumber: 'T20201208.0048',
		ticketType: 1,
		title: 'Create task',
		userDefinedFields: []
	},
	{
		id: 125415,
		apiVendorID: null,
		assignedResourceID: null,
		assignedResourceRoleID: null,
		billingCodeID: null,
		changeApprovalBoard: null,
		changeApprovalStatus: null,
		changeApprovalType: null,
		changeInfoField1: '',
		changeInfoField2: '',
		changeInfoField3: '',
		changeInfoField4: '',
		changeInfoField5: '',
		companyID: 29683968,
		companyLocationID: 273,
		completedByResourceID: null,
		completedDate: '2020-12-08T12:18:16Z',
		configurationItemID: null,
		contactID: 'Janne',
		contractID: null,
		contractServiceBundleID: null,
		contractServiceID: null,
		createDate: '2020-12-08T12:18:15.793Z',
		createdByContactID: null,
		creatorResourceID: 30683953,
		creatorType: 1,
		currentServiceThermometerRating: null,
		description: null,
		dueDateTime: '2020-12-11T12:18:00Z',
		estimatedHours: null,
		externalID: '',
		firstResponseAssignedResourceID: null,
		firstResponseDateTime: '2020-12-08T12:18:15.793Z',
		firstResponseDueDateTime: '2020-12-09T12:18:15.793Z',
		firstResponseInitiatingResourceID: 30683953,
		hoursToBeScheduled: 0.0,
		impersonatorCreatorResourceID: null,
		issueType: null,
		lastActivityDate: '2020-12-08T12:18:32.417Z',
		lastActivityPersonType: 1,
		lastActivityResourceID: 4,
		lastCustomerNotificationDateTime: null,
		lastCustomerVisibleActivityDateTime: null,
		lastTrackedModificationDateTime: '2020-12-08T12:18:32.417Z',
		monitorID: null,
		monitorTypeID: null,
		opportunityID: null,
		organizationalLevelAssociationID: null,
		previousServiceThermometerRating: null,
		priority: 3,
		problemTicketId: null,
		projectID: null,
		purchaseOrderNumber: '',
		queueID: 5,
		resolution: '',
		resolutionPlanDateTime: '2020-12-08T12:18:15.793Z',
		resolutionPlanDueDateTime: null,
		resolvedDateTime: '2020-12-08T12:18:15.793Z',
		resolvedDueDateTime: '2020-12-11T12:18:15.793Z',
		rmaStatus: null,
		rmaType: null,
		rmmAlertID: null,
		serviceLevelAgreementHasBeenMet: null,
		serviceLevelAgreementID: 1,
		serviceLevelAgreementPausedNextEventHours: null,
		serviceThermometerTemperature: null,
		source: null,
		status: 2,
		subIssueType: null,
		ticketCategory: 3,
		ticketNumber: 'T20201208.0047',
		ticketType: 1,
		title: 'Create task',
		userDefinedFields: []
	},
	{
		id: 125416,
		apiVendorID: null,
		assignedResourceID: null,
		assignedResourceRoleID: null,
		billingCodeID: null,
		changeApprovalBoard: null,
		changeApprovalStatus: null,
		changeApprovalType: null,
		changeInfoField1: '',
		changeInfoField2: '',
		changeInfoField3: '',
		changeInfoField4: '',
		changeInfoField5: '',
		companyID: 29683968,
		companyLocationID: 273,
		completedByResourceID: null,
		completedDate: '2020-12-08T12:18:16Z',
		configurationItemID: null,
		contactID: 'Kim',
		contractID: null,
		contractServiceBundleID: null,
		contractServiceID: null,
		createDate: '2020-12-08T12:18:15.793Z',
		createdByContactID: null,
		creatorResourceID: 30683953,
		creatorType: 1,
		currentServiceThermometerRating: null,
		description: null,
		dueDateTime: '2020-12-11T12:18:00Z',
		estimatedHours: null,
		externalID: '',
		firstResponseAssignedResourceID: null,
		firstResponseDateTime: '2020-12-08T12:18:15.793Z',
		firstResponseDueDateTime: '2020-12-09T12:18:15.793Z',
		firstResponseInitiatingResourceID: 30683953,
		hoursToBeScheduled: 0.0,
		impersonatorCreatorResourceID: null,
		issueType: null,
		lastActivityDate: '2020-12-08T12:18:32.417Z',
		lastActivityPersonType: 1,
		lastActivityResourceID: 4,
		lastCustomerNotificationDateTime: null,
		lastCustomerVisibleActivityDateTime: null,
		lastTrackedModificationDateTime: '2020-12-08T12:18:32.417Z',
		monitorID: null,
		monitorTypeID: null,
		opportunityID: null,
		organizationalLevelAssociationID: null,
		previousServiceThermometerRating: null,
		priority: 2,
		problemTicketId: null,
		projectID: null,
		purchaseOrderNumber: '',
		queueID: 5,
		resolution: '',
		resolutionPlanDateTime: '2020-12-08T12:18:15.793Z',
		resolutionPlanDueDateTime: null,
		resolvedDateTime: '2020-12-08T12:18:15.793Z',
		resolvedDueDateTime: '2020-12-11T12:18:15.793Z',
		rmaStatus: null,
		rmaType: null,
		rmmAlertID: null,
		serviceLevelAgreementHasBeenMet: null,
		serviceLevelAgreementID: 1,
		serviceLevelAgreementPausedNextEventHours: null,
		serviceThermometerTemperature: null,
		source: null,
		status: 3,
		subIssueType: null,
		ticketCategory: 3,
		ticketNumber: 'T20201208.0047',
		ticketType: 1,
		title: 'Create task',
		userDefinedFields: []
	},
	{
		id: 125417,
		apiVendorID: null,
		assignedResourceID: null,
		assignedResourceRoleID: null,
		billingCodeID: null,
		changeApprovalBoard: null,
		changeApprovalStatus: null,
		changeApprovalType: null,
		changeInfoField1: '',
		changeInfoField2: '',
		changeInfoField3: '',
		changeInfoField4: '',
		changeInfoField5: '',
		companyID: 29683968,
		companyLocationID: 273,
		completedByResourceID: null,
		completedDate: '2020-12-08T12:18:16Z',
		configurationItemID: null,
		contactID: 'Kim',
		contractID: null,
		contractServiceBundleID: null,
		contractServiceID: null,
		createDate: '2020-12-08T12:18:15.793Z',
		createdByContactID: null,
		creatorResourceID: 30683953,
		creatorType: 1,
		currentServiceThermometerRating: null,
		description: null,
		dueDateTime: '2020-12-11T12:18:00Z',
		estimatedHours: null,
		externalID: '',
		firstResponseAssignedResourceID: null,
		firstResponseDateTime: '2020-12-08T12:18:15.793Z',
		firstResponseDueDateTime: '2020-12-09T12:18:15.793Z',
		firstResponseInitiatingResourceID: 30683953,
		hoursToBeScheduled: 0.0,
		impersonatorCreatorResourceID: null,
		issueType: null,
		lastActivityDate: '2020-12-08T12:18:32.417Z',
		lastActivityPersonType: 1,
		lastActivityResourceID: 4,
		lastCustomerNotificationDateTime: null,
		lastCustomerVisibleActivityDateTime: null,
		lastTrackedModificationDateTime: '2020-12-08T12:18:32.417Z',
		monitorID: null,
		monitorTypeID: null,
		opportunityID: null,
		organizationalLevelAssociationID: null,
		previousServiceThermometerRating: null,
		priority: 2,
		problemTicketId: null,
		projectID: null,
		purchaseOrderNumber: '',
		queueID: 5,
		resolution: '',
		resolutionPlanDateTime: '2020-12-08T12:18:15.793Z',
		resolutionPlanDueDateTime: null,
		resolvedDateTime: '2020-12-08T12:18:15.793Z',
		resolvedDueDateTime: '2020-12-11T12:18:15.793Z',
		rmaStatus: null,
		rmaType: null,
		rmmAlertID: null,
		serviceLevelAgreementHasBeenMet: null,
		serviceLevelAgreementID: 1,
		serviceLevelAgreementPausedNextEventHours: null,
		serviceThermometerTemperature: null,
		source: null,
		status: 1,
		subIssueType: null,
		ticketCategory: 3,
		ticketNumber: 'T20201208.0047',
		ticketType: 1,
		title: 'Create task',
		userDefinedFields: []
	},
	,
	{
		id: 125418,
		apiVendorID: null,
		assignedResourceID: null,
		assignedResourceRoleID: null,
		billingCodeID: null,
		changeApprovalBoard: null,
		changeApprovalStatus: null,
		changeApprovalType: null,
		changeInfoField1: '',
		changeInfoField2: '',
		changeInfoField3: '',
		changeInfoField4: '',
		changeInfoField5: '',
		companyID: 29683968,
		companyLocationID: 273,
		completedByResourceID: null,
		completedDate: '2020-12-08T12:18:16Z',
		configurationItemID: null,
		contactID: 'Kim',
		contractID: null,
		contractServiceBundleID: null,
		contractServiceID: null,
		createDate: '2020-12-08T12:18:15.793Z',
		createdByContactID: null,
		creatorResourceID: 30683953,
		creatorType: 1,
		currentServiceThermometerRating: null,
		description: null,
		dueDateTime: '2020-12-11T12:18:00Z',
		estimatedHours: null,
		externalID: '',
		firstResponseAssignedResourceID: null,
		firstResponseDateTime: '2020-12-08T12:18:15.793Z',
		firstResponseDueDateTime: '2020-12-09T12:18:15.793Z',
		firstResponseInitiatingResourceID: 30683953,
		hoursToBeScheduled: 0.0,
		impersonatorCreatorResourceID: null,
		issueType: null,
		lastActivityDate: '2020-12-08T12:18:32.417Z',
		lastActivityPersonType: 1,
		lastActivityResourceID: 4,
		lastCustomerNotificationDateTime: null,
		lastCustomerVisibleActivityDateTime: null,
		lastTrackedModificationDateTime: '2020-12-08T12:18:32.417Z',
		monitorID: null,
		monitorTypeID: null,
		opportunityID: null,
		organizationalLevelAssociationID: null,
		previousServiceThermometerRating: null,
		priority: 2,
		problemTicketId: null,
		projectID: null,
		purchaseOrderNumber: '',
		queueID: 5,
		resolution: '',
		resolutionPlanDateTime: '2020-12-08T12:18:15.793Z',
		resolutionPlanDueDateTime: null,
		resolvedDateTime: '2020-12-08T12:18:15.793Z',
		resolvedDueDateTime: '2020-12-11T12:18:15.793Z',
		rmaStatus: null,
		rmaType: null,
		rmmAlertID: null,
		serviceLevelAgreementHasBeenMet: null,
		serviceLevelAgreementID: 1,
		serviceLevelAgreementPausedNextEventHours: null,
		serviceThermometerTemperature: null,
		source: null,
		status: 1,
		subIssueType: null,
		ticketCategory: 3,
		ticketNumber: 'T20201208.0047',
		ticketType: 1,
		title: 'Create task',
		userDefinedFields: []
	},
	{
		id: 125419,
		apiVendorID: null,
		assignedResourceID: null,
		assignedResourceRoleID: null,
		billingCodeID: null,
		changeApprovalBoard: null,
		changeApprovalStatus: null,
		changeApprovalType: null,
		changeInfoField1: '',
		changeInfoField2: '',
		changeInfoField3: '',
		changeInfoField4: '',
		changeInfoField5: '',
		companyID: 29683968,
		companyLocationID: 273,
		completedByResourceID: null,
		completedDate: '2020-12-08T12:18:16Z',
		configurationItemID: null,
		contactID: 'Kim',
		contractID: null,
		contractServiceBundleID: null,
		contractServiceID: null,
		createDate: '2020-12-08T12:18:15.793Z',
		createdByContactID: null,
		creatorResourceID: 30683953,
		creatorType: 1,
		currentServiceThermometerRating: null,
		description: null,
		dueDateTime: '2020-12-11T12:18:00Z',
		estimatedHours: null,
		externalID: '',
		firstResponseAssignedResourceID: null,
		firstResponseDateTime: '2020-12-08T12:18:15.793Z',
		firstResponseDueDateTime: '2020-12-09T12:18:15.793Z',
		firstResponseInitiatingResourceID: 30683953,
		hoursToBeScheduled: 0.0,
		impersonatorCreatorResourceID: null,
		issueType: null,
		lastActivityDate: '2020-12-08T12:18:32.417Z',
		lastActivityPersonType: 1,
		lastActivityResourceID: 4,
		lastCustomerNotificationDateTime: null,
		lastCustomerVisibleActivityDateTime: null,
		lastTrackedModificationDateTime: '2020-12-08T12:18:32.417Z',
		monitorID: null,
		monitorTypeID: null,
		opportunityID: null,
		organizationalLevelAssociationID: null,
		previousServiceThermometerRating: null,
		priority: 2,
		problemTicketId: null,
		projectID: null,
		purchaseOrderNumber: '',
		queueID: 5,
		resolution: '',
		resolutionPlanDateTime: '2020-12-08T12:18:15.793Z',
		resolutionPlanDueDateTime: null,
		resolvedDateTime: '2020-12-08T12:18:15.793Z',
		resolvedDueDateTime: '2020-12-11T12:18:15.793Z',
		rmaStatus: null,
		rmaType: null,
		rmmAlertID: null,
		serviceLevelAgreementHasBeenMet: null,
		serviceLevelAgreementID: 1,
		serviceLevelAgreementPausedNextEventHours: null,
		serviceThermometerTemperature: null,
		source: null,
		status: 1,
		subIssueType: null,
		ticketCategory: 3,
		ticketNumber: 'T20201208.0047',
		ticketType: 1,
		title: 'Create task',
		userDefinedFields: []
	},
	{
		id: 125420,
		apiVendorID: null,
		assignedResourceID: null,
		assignedResourceRoleID: null,
		billingCodeID: null,
		changeApprovalBoard: null,
		changeApprovalStatus: null,
		changeApprovalType: null,
		changeInfoField1: '',
		changeInfoField2: '',
		changeInfoField3: '',
		changeInfoField4: '',
		changeInfoField5: '',
		companyID: 29683968,
		companyLocationID: 273,
		completedByResourceID: null,
		completedDate: '2020-12-08T12:18:16Z',
		configurationItemID: null,
		contactID: 'Kim',
		contractID: null,
		contractServiceBundleID: null,
		contractServiceID: null,
		createDate: '2020-12-08T12:18:15.793Z',
		createdByContactID: null,
		creatorResourceID: 30683953,
		creatorType: 1,
		currentServiceThermometerRating: null,
		description: null,
		dueDateTime: '2020-12-11T12:18:00Z',
		estimatedHours: null,
		externalID: '',
		firstResponseAssignedResourceID: null,
		firstResponseDateTime: '2020-12-08T12:18:15.793Z',
		firstResponseDueDateTime: '2020-12-09T12:18:15.793Z',
		firstResponseInitiatingResourceID: 30683953,
		hoursToBeScheduled: 0.0,
		impersonatorCreatorResourceID: null,
		issueType: null,
		lastActivityDate: '2020-12-08T12:18:32.417Z',
		lastActivityPersonType: 1,
		lastActivityResourceID: 4,
		lastCustomerNotificationDateTime: null,
		lastCustomerVisibleActivityDateTime: null,
		lastTrackedModificationDateTime: '2020-12-08T12:18:32.417Z',
		monitorID: null,
		monitorTypeID: null,
		opportunityID: null,
		organizationalLevelAssociationID: null,
		previousServiceThermometerRating: null,
		priority: 2,
		problemTicketId: null,
		projectID: null,
		purchaseOrderNumber: '',
		queueID: 5,
		resolution: '',
		resolutionPlanDateTime: '2020-12-08T12:18:15.793Z',
		resolutionPlanDueDateTime: null,
		resolvedDateTime: '2020-12-08T12:18:15.793Z',
		resolvedDueDateTime: '2020-12-11T12:18:15.793Z',
		rmaStatus: null,
		rmaType: null,
		rmmAlertID: null,
		serviceLevelAgreementHasBeenMet: null,
		serviceLevelAgreementID: 1,
		serviceLevelAgreementPausedNextEventHours: null,
		serviceThermometerTemperature: null,
		source: null,
		status: 1,
		subIssueType: null,
		ticketCategory: 3,
		ticketNumber: 'T20201208.0047',
		ticketType: 1,
		title: 'Create task',
		userDefinedFields: []
	}
]
const devices = [
	{
		id: 0,
		billingType: 0,
		chargeBillingCodeID: 0,
		defaultVendorID: 0,
		description: 'string',
		doesNotRequireProcurement: true,
		externalProductID: 'string',
		impersonatorCreatorResourceID: 0,
		internalProductID: 'string',
		isActive: true,
		isEligibleForRma: true,
		isSerialized: true,
		link: 'string',
		manufacturerName: 'string',
		manufacturerProductName: 'string',
		markupRate: 0,
		mSRP: 0,
		name: 'string',
		periodType: 0,
		priceCostMethod: 0,
		productBillingCodeID: 0,
		productCategory: 0,
		sKU: 'string',
		unitCost: 0,
		unitPrice: 0,
		vendorProductNumber: 'string',
		defaultInstalledProductCategoryID: 0,
		userDefinedFields: [
			{
				Name: 'string',
				Value: 'string'
			}
		]
	}
]
const locations = [
	{
		id: 273,
		address1: 'Demotie 1',
		address2: '',
		alternatePhone1: '',
		alternatePhone2: '',
		city: 'Espoo',
		companyID: 29683968,
		countryID: 76,
		description: '',
		fax: '',
		isActive: true,
		isPrimary: true,
		isTaxExempt: null,
		overrideCompanyTaxSettings: false,
		name: 'Primary Address',
		phone: '+358123456789',
		postalCode: '02600',
		roundtripDistance: null,
		state: '',
		taxRegionID: null
	},
	{
		id: 274,
		address1: 'Majurinkatu 3A',
		address2: '',
		alternatePhone1: '',
		alternatePhone2: '',
		city: 'Espoo',
		companyID: 29683968,
		countryID: 76,
		description: '',
		fax: '',
		isActive: true,
		isPrimary: true,
		isTaxExempt: null,
		overrideCompanyTaxSettings: false,
		name: 'Secondary Address',
		phone: '+358123456789',
		postalCode: '02600',
		roundtripDistance: 12,
		state: '',
		taxRegionID: null
	}
]
const COUNTRY_DATA = [
	{
		id: 1,
		addressFormatID: 1,
		countryCode: 'XX',
		displayName: 'Other',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Other',
		quoteTemplateID: 1
	},
	{
		id: 2,
		addressFormatID: 1,
		countryCode: 'AF',
		displayName: 'Afghanistan',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Afghanistan',
		quoteTemplateID: 1
	},
	{
		id: 3,
		addressFormatID: 1,
		countryCode: 'AX',
		displayName: 'Åland Islands',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Åland Islands',
		quoteTemplateID: 1
	},
	{
		id: 4,
		addressFormatID: 1,
		countryCode: 'AL',
		displayName: 'Albania',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Albania',
		quoteTemplateID: 1
	},
	{
		id: 5,
		addressFormatID: 1,
		countryCode: 'DZ',
		displayName: 'Algeria',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Algeria',
		quoteTemplateID: 1
	},
	{
		id: 6,
		addressFormatID: 1,
		countryCode: 'AS',
		displayName: 'American Samoa',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'American Samoa',
		quoteTemplateID: 1
	},
	{
		id: 7,
		addressFormatID: 1,
		countryCode: 'AD',
		displayName: 'Andorra',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Andorra',
		quoteTemplateID: 1
	},
	{
		id: 8,
		addressFormatID: 1,
		countryCode: 'AO',
		displayName: 'Angola',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Angola',
		quoteTemplateID: 1
	},
	{
		id: 9,
		addressFormatID: 1,
		countryCode: 'AI',
		displayName: 'Anguilla',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Anguilla',
		quoteTemplateID: 1
	},
	{
		id: 10,
		addressFormatID: 1,
		countryCode: 'AQ',
		displayName: 'Antarctica',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Antarctica',
		quoteTemplateID: 1
	},
	{
		id: 11,
		addressFormatID: 1,
		countryCode: 'AG',
		displayName: 'Antigua And Barbuda',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Antigua And Barbuda',
		quoteTemplateID: 1
	},
	{
		id: 12,
		addressFormatID: 1,
		countryCode: 'AR',
		displayName: 'Argentina',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Argentina',
		quoteTemplateID: 1
	},
	{
		id: 13,
		addressFormatID: 1,
		countryCode: 'AM',
		displayName: 'Armenia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Armenia',
		quoteTemplateID: 1
	},
	{
		id: 14,
		addressFormatID: 1,
		countryCode: 'AW',
		displayName: 'Aruba',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Aruba',
		quoteTemplateID: 1
	},
	{
		id: 15,
		addressFormatID: 2,
		countryCode: 'AU',
		displayName: 'Australia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Australia',
		quoteTemplateID: 1
	},
	{
		id: 16,
		addressFormatID: 6,
		countryCode: 'AT',
		displayName: 'Austria',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Austria',
		quoteTemplateID: 1
	},
	{
		id: 17,
		addressFormatID: 1,
		countryCode: 'AZ',
		displayName: 'Azerbaijan',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Azerbaijan',
		quoteTemplateID: 1
	},
	{
		id: 18,
		addressFormatID: 1,
		countryCode: 'BS',
		displayName: 'Bahamas',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Bahamas',
		quoteTemplateID: 1
	},
	{
		id: 19,
		addressFormatID: 1,
		countryCode: 'BH',
		displayName: 'Bahrain',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Bahrain',
		quoteTemplateID: 1
	},
	{
		id: 20,
		addressFormatID: 1,
		countryCode: 'BD',
		displayName: 'Bangladesh',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Bangladesh',
		quoteTemplateID: 1
	},
	{
		id: 21,
		addressFormatID: 1,
		countryCode: 'BB',
		displayName: 'Barbados',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Barbados',
		quoteTemplateID: 1
	},
	{
		id: 22,
		addressFormatID: 1,
		countryCode: 'BY',
		displayName: 'Belarus',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Belarus',
		quoteTemplateID: 1
	},
	{
		id: 23,
		addressFormatID: 6,
		countryCode: 'BE',
		displayName: 'Belgium',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Belgium',
		quoteTemplateID: 1
	},
	{
		id: 24,
		addressFormatID: 1,
		countryCode: 'BZ',
		displayName: 'Belize',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Belize',
		quoteTemplateID: 1
	},
	{
		id: 25,
		addressFormatID: 1,
		countryCode: 'BJ',
		displayName: 'Benin',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Benin',
		quoteTemplateID: 1
	},
	{
		id: 26,
		addressFormatID: 1,
		countryCode: 'BM',
		displayName: 'Bermuda',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Bermuda',
		quoteTemplateID: 1
	},
	{
		id: 27,
		addressFormatID: 1,
		countryCode: 'BT',
		displayName: 'Bhutan',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Bhutan',
		quoteTemplateID: 1
	},
	{
		id: 28,
		addressFormatID: 1,
		countryCode: 'BO',
		displayName: 'Bolivia, Plurinational State Of',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Bolivia, Plurinational State Of',
		quoteTemplateID: 1
	},
	{
		id: 29,
		addressFormatID: 1,
		countryCode: 'BQ',
		displayName: 'Bonaire, Sint Eustatius And Saba',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Bonaire, Sint Eustatius And Saba',
		quoteTemplateID: 1
	},
	{
		id: 30,
		addressFormatID: 1,
		countryCode: 'BA',
		displayName: 'Bosnia And Herzegovina',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Bosnia And Herzegovina',
		quoteTemplateID: 1
	},
	{
		id: 31,
		addressFormatID: 1,
		countryCode: 'BW',
		displayName: 'Botswana',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Botswana',
		quoteTemplateID: 1
	},
	{
		id: 32,
		addressFormatID: 1,
		countryCode: 'BV',
		displayName: 'Bouvet Island',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Bouvet Island',
		quoteTemplateID: 1
	},
	{
		id: 33,
		addressFormatID: 1,
		countryCode: 'BR',
		displayName: 'Brazil',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Brazil',
		quoteTemplateID: 1
	},
	{
		id: 34,
		addressFormatID: 1,
		countryCode: 'IO',
		displayName: 'British Indian Ocean Territory',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'British Indian Ocean Territory',
		quoteTemplateID: 1
	},
	{
		id: 35,
		addressFormatID: 1,
		countryCode: 'BN',
		displayName: 'Brunei Darussalam',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Brunei Darussalam',
		quoteTemplateID: 1
	},
	{
		id: 36,
		addressFormatID: 1,
		countryCode: 'BG',
		displayName: 'Bulgaria',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Bulgaria',
		quoteTemplateID: 1
	},
	{
		id: 37,
		addressFormatID: 1,
		countryCode: 'BF',
		displayName: 'Burkina Faso',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Burkina Faso',
		quoteTemplateID: 1
	},
	{
		id: 38,
		addressFormatID: 1,
		countryCode: 'BI',
		displayName: 'Burundi',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Burundi',
		quoteTemplateID: 1
	},
	{
		id: 39,
		addressFormatID: 1,
		countryCode: 'KH',
		displayName: 'Cambodia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Cambodia',
		quoteTemplateID: 1
	},
	{
		id: 40,
		addressFormatID: 1,
		countryCode: 'CM',
		displayName: 'Cameroon',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Cameroon',
		quoteTemplateID: 1
	},
	{
		id: 41,
		addressFormatID: 1,
		countryCode: 'CA',
		displayName: 'Canada',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Canada',
		quoteTemplateID: 1
	},
	{
		id: 42,
		addressFormatID: 1,
		countryCode: 'CV',
		displayName: 'Cape Verde',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Cape Verde',
		quoteTemplateID: 1
	},
	{
		id: 43,
		addressFormatID: 1,
		countryCode: 'KY',
		displayName: 'Cayman Islands',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Cayman Islands',
		quoteTemplateID: 1
	},
	{
		id: 44,
		addressFormatID: 1,
		countryCode: 'CF',
		displayName: 'Central African Republic',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Central African Republic',
		quoteTemplateID: 1
	},
	{
		id: 45,
		addressFormatID: 1,
		countryCode: 'TD',
		displayName: 'Chad',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Chad',
		quoteTemplateID: 1
	},
	{
		id: 46,
		addressFormatID: 1,
		countryCode: 'CL',
		displayName: 'Chile',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Chile',
		quoteTemplateID: 1
	},
	{
		id: 47,
		addressFormatID: 1,
		countryCode: 'CN',
		displayName: 'China',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'China',
		quoteTemplateID: 1
	},
	{
		id: 48,
		addressFormatID: 1,
		countryCode: 'CX',
		displayName: 'Christmas Island',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Christmas Island',
		quoteTemplateID: 1
	},
	{
		id: 49,
		addressFormatID: 1,
		countryCode: 'CC',
		displayName: 'Cocos (Keeling) Islands',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Cocos (Keeling) Islands',
		quoteTemplateID: 1
	},
	{
		id: 50,
		addressFormatID: 1,
		countryCode: 'CO',
		displayName: 'Colombia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Colombia',
		quoteTemplateID: 1
	},
	{
		id: 51,
		addressFormatID: 1,
		countryCode: 'KM',
		displayName: 'Comoros',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Comoros',
		quoteTemplateID: 1
	},
	{
		id: 52,
		addressFormatID: 1,
		countryCode: 'CG',
		displayName: 'Congo',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Congo',
		quoteTemplateID: 1
	},
	{
		id: 53,
		addressFormatID: 1,
		countryCode: 'CD',
		displayName: 'Congo, The Democratic Republic Of The',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Congo, The Democratic Republic Of The',
		quoteTemplateID: 1
	},
	{
		id: 54,
		addressFormatID: 1,
		countryCode: 'CK',
		displayName: 'Cook Islands',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Cook Islands',
		quoteTemplateID: 1
	},
	{
		id: 55,
		addressFormatID: 1,
		countryCode: 'CR',
		displayName: 'Costa Rica',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Costa Rica',
		quoteTemplateID: 1
	},
	{
		id: 56,
		addressFormatID: 1,
		countryCode: 'CI',
		displayName: "Côte D'Ivoire",
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: "Côte D'Ivoire",
		quoteTemplateID: 1
	},
	{
		id: 57,
		addressFormatID: 1,
		countryCode: 'HR',
		displayName: 'Croatia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Croatia',
		quoteTemplateID: 1
	},
	{
		id: 58,
		addressFormatID: 1,
		countryCode: 'CU',
		displayName: 'Cuba',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Cuba',
		quoteTemplateID: 1
	},
	{
		id: 59,
		addressFormatID: 1,
		countryCode: 'CW',
		displayName: 'Curaçao',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Curaçao',
		quoteTemplateID: 1
	},
	{
		id: 60,
		addressFormatID: 1,
		countryCode: 'CY',
		displayName: 'Cyprus',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Cyprus',
		quoteTemplateID: 1
	},
	{
		id: 61,
		addressFormatID: 1,
		countryCode: 'CZ',
		displayName: 'Czech Republic',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Czech Republic',
		quoteTemplateID: 1
	},
	{
		id: 62,
		addressFormatID: 1,
		countryCode: 'DK',
		displayName: 'Denmark',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Denmark',
		quoteTemplateID: 1
	},
	{
		id: 63,
		addressFormatID: 1,
		countryCode: 'DJ',
		displayName: 'Djibouti',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Djibouti',
		quoteTemplateID: 1
	},
	{
		id: 64,
		addressFormatID: 1,
		countryCode: 'DM',
		displayName: 'Dominica',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Dominica',
		quoteTemplateID: 1
	},
	{
		id: 65,
		addressFormatID: 1,
		countryCode: 'DO',
		displayName: 'Dominican Republic',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Dominican Republic',
		quoteTemplateID: 1
	},
	{
		id: 66,
		addressFormatID: 1,
		countryCode: 'EC',
		displayName: 'Ecuador',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Ecuador',
		quoteTemplateID: 1
	},
	{
		id: 67,
		addressFormatID: 1,
		countryCode: 'EG',
		displayName: 'Egypt',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Egypt',
		quoteTemplateID: 1
	},
	{
		id: 68,
		addressFormatID: 1,
		countryCode: 'SV',
		displayName: 'El Salvador',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'El Salvador',
		quoteTemplateID: 1
	},
	{
		id: 69,
		addressFormatID: 1,
		countryCode: 'GQ',
		displayName: 'Equatorial Guinea',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Equatorial Guinea',
		quoteTemplateID: 1
	},
	{
		id: 70,
		addressFormatID: 1,
		countryCode: 'ER',
		displayName: 'Eritrea',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Eritrea',
		quoteTemplateID: 1
	},
	{
		id: 71,
		addressFormatID: 1,
		countryCode: 'EE',
		displayName: 'Estonia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Estonia',
		quoteTemplateID: 1
	},
	{
		id: 72,
		addressFormatID: 1,
		countryCode: 'ET',
		displayName: 'Ethiopia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Ethiopia',
		quoteTemplateID: 1
	},
	{
		id: 73,
		addressFormatID: 1,
		countryCode: 'FK',
		displayName: 'Falkland Islands (Malvinas)',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Falkland Islands (Malvinas)',
		quoteTemplateID: 1
	},
	{
		id: 74,
		addressFormatID: 1,
		countryCode: 'FO',
		displayName: 'Faroe Islands',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Faroe Islands',
		quoteTemplateID: 1
	},
	{
		id: 75,
		addressFormatID: 1,
		countryCode: 'FJ',
		displayName: 'Fiji',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Fiji',
		quoteTemplateID: 1
	},
	{
		id: 76,
		addressFormatID: 1,
		countryCode: 'FI',
		displayName: 'Finland',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Finland',
		quoteTemplateID: 1
	},
	{
		id: 77,
		addressFormatID: 1,
		countryCode: 'FR',
		displayName: 'France',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'France',
		quoteTemplateID: 1
	},
	{
		id: 78,
		addressFormatID: 1,
		countryCode: 'GF',
		displayName: 'French Guiana',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'French Guiana',
		quoteTemplateID: 1
	},
	{
		id: 79,
		addressFormatID: 1,
		countryCode: 'PF',
		displayName: 'French Polynesia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'French Polynesia',
		quoteTemplateID: 1
	},
	{
		id: 80,
		addressFormatID: 1,
		countryCode: 'TF',
		displayName: 'French Southern Territories',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'French Southern Territories',
		quoteTemplateID: 1
	},
	{
		id: 81,
		addressFormatID: 1,
		countryCode: 'GA',
		displayName: 'Gabon',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Gabon',
		quoteTemplateID: 1
	},
	{
		id: 82,
		addressFormatID: 1,
		countryCode: 'GM',
		displayName: 'Gambia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Gambia',
		quoteTemplateID: 1
	},
	{
		id: 83,
		addressFormatID: 1,
		countryCode: 'GE',
		displayName: 'Georgia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Georgia',
		quoteTemplateID: 1
	},
	{
		id: 84,
		addressFormatID: 6,
		countryCode: 'DE',
		displayName: 'Germany',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Germany',
		quoteTemplateID: 1
	},
	{
		id: 85,
		addressFormatID: 1,
		countryCode: 'GH',
		displayName: 'Ghana',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Ghana',
		quoteTemplateID: 1
	},
	{
		id: 86,
		addressFormatID: 1,
		countryCode: 'GI',
		displayName: 'Gibraltar',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Gibraltar',
		quoteTemplateID: 1
	},
	{
		id: 87,
		addressFormatID: 1,
		countryCode: 'GR',
		displayName: 'Greece',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Greece',
		quoteTemplateID: 1
	},
	{
		id: 88,
		addressFormatID: 1,
		countryCode: 'GL',
		displayName: 'Greenland',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Greenland',
		quoteTemplateID: 1
	},
	{
		id: 89,
		addressFormatID: 1,
		countryCode: 'GD',
		displayName: 'Grenada',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Grenada',
		quoteTemplateID: 1
	},
	{
		id: 90,
		addressFormatID: 1,
		countryCode: 'GP',
		displayName: 'Guadeloupe',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Guadeloupe',
		quoteTemplateID: 1
	},
	{
		id: 91,
		addressFormatID: 1,
		countryCode: 'GU',
		displayName: 'Guam',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Guam',
		quoteTemplateID: 1
	},
	{
		id: 92,
		addressFormatID: 1,
		countryCode: 'GT',
		displayName: 'Guatemala',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Guatemala',
		quoteTemplateID: 1
	},
	{
		id: 93,
		addressFormatID: 1,
		countryCode: 'GG',
		displayName: 'Guernsey',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Guernsey',
		quoteTemplateID: 1
	},
	{
		id: 94,
		addressFormatID: 1,
		countryCode: 'GN',
		displayName: 'Guinea',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Guinea',
		quoteTemplateID: 1
	},
	{
		id: 95,
		addressFormatID: 1,
		countryCode: 'GW',
		displayName: 'Guinea-Bissau',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Guinea-Bissau',
		quoteTemplateID: 1
	},
	{
		id: 96,
		addressFormatID: 1,
		countryCode: 'GY',
		displayName: 'Guyana',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Guyana',
		quoteTemplateID: 1
	},
	{
		id: 97,
		addressFormatID: 1,
		countryCode: 'HT',
		displayName: 'Haiti',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Haiti',
		quoteTemplateID: 1
	},
	{
		id: 98,
		addressFormatID: 1,
		countryCode: 'HM',
		displayName: 'Heard Island And Mcdonald Islands',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Heard Island And Mcdonald Islands',
		quoteTemplateID: 1
	},
	{
		id: 99,
		addressFormatID: 1,
		countryCode: 'VA',
		displayName: 'Holy See (Vatican City State)',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Holy See (Vatican City State)',
		quoteTemplateID: 1
	},
	{
		id: 100,
		addressFormatID: 1,
		countryCode: 'HN',
		displayName: 'Honduras',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Honduras',
		quoteTemplateID: 1
	},
	{
		id: 101,
		addressFormatID: 1,
		countryCode: 'HK',
		displayName: 'Hong Kong',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Hong Kong',
		quoteTemplateID: 1
	},
	{
		id: 102,
		addressFormatID: 1,
		countryCode: 'HU',
		displayName: 'Hungary',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Hungary',
		quoteTemplateID: 1
	},
	{
		id: 103,
		addressFormatID: 1,
		countryCode: 'IS',
		displayName: 'Iceland',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Iceland',
		quoteTemplateID: 1
	},
	{
		id: 104,
		addressFormatID: 1,
		countryCode: 'IN',
		displayName: 'India',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'India',
		quoteTemplateID: 1
	},
	{
		id: 105,
		addressFormatID: 1,
		countryCode: 'ID',
		displayName: 'Indonesia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Indonesia',
		quoteTemplateID: 1
	},
	{
		id: 106,
		addressFormatID: 1,
		countryCode: 'IR',
		displayName: 'Iran, Islamic Republic Of',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Iran, Islamic Republic Of',
		quoteTemplateID: 1
	},
	{
		id: 107,
		addressFormatID: 1,
		countryCode: 'IQ',
		displayName: 'Iraq',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Iraq',
		quoteTemplateID: 1
	},
	{
		id: 108,
		addressFormatID: 1,
		countryCode: 'IE',
		displayName: 'Ireland',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Ireland',
		quoteTemplateID: 1
	},
	{
		id: 109,
		addressFormatID: 1,
		countryCode: 'IM',
		displayName: 'Isle Of Man',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Isle Of Man',
		quoteTemplateID: 1
	},
	{
		id: 110,
		addressFormatID: 1,
		countryCode: 'IL',
		displayName: 'Israel',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Israel',
		quoteTemplateID: 1
	},
	{
		id: 111,
		addressFormatID: 1,
		countryCode: 'IT',
		displayName: 'Italy',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Italy',
		quoteTemplateID: 1
	},
	{
		id: 112,
		addressFormatID: 1,
		countryCode: 'JM',
		displayName: 'Jamaica',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Jamaica',
		quoteTemplateID: 1
	},
	{
		id: 113,
		addressFormatID: 1,
		countryCode: 'JP',
		displayName: 'Japan',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Japan',
		quoteTemplateID: 1
	},
	{
		id: 114,
		addressFormatID: 1,
		countryCode: 'JE',
		displayName: 'Jersey',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Jersey',
		quoteTemplateID: 1
	},
	{
		id: 115,
		addressFormatID: 1,
		countryCode: 'JO',
		displayName: 'Jordan',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Jordan',
		quoteTemplateID: 1
	},
	{
		id: 116,
		addressFormatID: 1,
		countryCode: 'KZ',
		displayName: 'Kazakhstan',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Kazakhstan',
		quoteTemplateID: 1
	},
	{
		id: 117,
		addressFormatID: 1,
		countryCode: 'KE',
		displayName: 'Kenya',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Kenya',
		quoteTemplateID: 1
	},
	{
		id: 118,
		addressFormatID: 1,
		countryCode: 'KI',
		displayName: 'Kiribati',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Kiribati',
		quoteTemplateID: 1
	},
	{
		id: 119,
		addressFormatID: 1,
		countryCode: 'KP',
		displayName: "Korea, Democratic People'S Republic Of",
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: "Korea, Democratic People'S Republic Of",
		quoteTemplateID: 1
	},
	{
		id: 120,
		addressFormatID: 1,
		countryCode: 'KR',
		displayName: 'Korea, Republic Of',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Korea, Republic Of',
		quoteTemplateID: 1
	},
	{
		id: 121,
		addressFormatID: 1,
		countryCode: 'KW',
		displayName: 'Kuwait',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Kuwait',
		quoteTemplateID: 1
	},
	{
		id: 122,
		addressFormatID: 1,
		countryCode: 'KG',
		displayName: 'Kyrgyzstan',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Kyrgyzstan',
		quoteTemplateID: 1
	},
	{
		id: 123,
		addressFormatID: 1,
		countryCode: 'LA',
		displayName: "Lao People'S Democratic Republic",
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: "Lao People'S Democratic Republic",
		quoteTemplateID: 1
	},
	{
		id: 124,
		addressFormatID: 1,
		countryCode: 'LV',
		displayName: 'Latvia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Latvia',
		quoteTemplateID: 1
	},
	{
		id: 125,
		addressFormatID: 1,
		countryCode: 'LB',
		displayName: 'Lebanon',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Lebanon',
		quoteTemplateID: 1
	},
	{
		id: 126,
		addressFormatID: 1,
		countryCode: 'LS',
		displayName: 'Lesotho',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Lesotho',
		quoteTemplateID: 1
	},
	{
		id: 127,
		addressFormatID: 1,
		countryCode: 'LR',
		displayName: 'Liberia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Liberia',
		quoteTemplateID: 1
	},
	{
		id: 128,
		addressFormatID: 1,
		countryCode: 'LY',
		displayName: 'Libya',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Libya',
		quoteTemplateID: 1
	},
	{
		id: 129,
		addressFormatID: 1,
		countryCode: 'LI',
		displayName: 'Liechtenstein',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Liechtenstein',
		quoteTemplateID: 1
	},
	{
		id: 130,
		addressFormatID: 1,
		countryCode: 'LT',
		displayName: 'Lithuania',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Lithuania',
		quoteTemplateID: 1
	},
	{
		id: 131,
		addressFormatID: 1,
		countryCode: 'LU',
		displayName: 'Luxembourg',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Luxembourg',
		quoteTemplateID: 1
	},
	{
		id: 132,
		addressFormatID: 1,
		countryCode: 'MO',
		displayName: 'Macao',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Macao',
		quoteTemplateID: 1
	},
	{
		id: 133,
		addressFormatID: 1,
		countryCode: 'MK',
		displayName: 'Macedonia, The Former Yugoslav Republic Of',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Macedonia, The Former Yugoslav Republic Of',
		quoteTemplateID: 1
	},
	{
		id: 134,
		addressFormatID: 1,
		countryCode: 'MG',
		displayName: 'Madagascar',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Madagascar',
		quoteTemplateID: 1
	},
	{
		id: 135,
		addressFormatID: 1,
		countryCode: 'MW',
		displayName: 'Malawi',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Malawi',
		quoteTemplateID: 1
	},
	{
		id: 136,
		addressFormatID: 1,
		countryCode: 'MY',
		displayName: 'Malaysia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Malaysia',
		quoteTemplateID: 1
	},
	{
		id: 137,
		addressFormatID: 1,
		countryCode: 'MV',
		displayName: 'Maldives',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Maldives',
		quoteTemplateID: 1
	},
	{
		id: 138,
		addressFormatID: 1,
		countryCode: 'ML',
		displayName: 'Mali',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Mali',
		quoteTemplateID: 1
	},
	{
		id: 139,
		addressFormatID: 1,
		countryCode: 'MT',
		displayName: 'Malta',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Malta',
		quoteTemplateID: 1
	},
	{
		id: 140,
		addressFormatID: 1,
		countryCode: 'MH',
		displayName: 'Marshall Islands',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Marshall Islands',
		quoteTemplateID: 1
	},
	{
		id: 141,
		addressFormatID: 1,
		countryCode: 'MQ',
		displayName: 'Martinique',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Martinique',
		quoteTemplateID: 1
	},
	{
		id: 142,
		addressFormatID: 1,
		countryCode: 'MR',
		displayName: 'Mauritania',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Mauritania',
		quoteTemplateID: 1
	},
	{
		id: 143,
		addressFormatID: 1,
		countryCode: 'MU',
		displayName: 'Mauritius',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Mauritius',
		quoteTemplateID: 1
	},
	{
		id: 144,
		addressFormatID: 1,
		countryCode: 'YT',
		displayName: 'Mayotte',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Mayotte',
		quoteTemplateID: 1
	},
	{
		id: 145,
		addressFormatID: 1,
		countryCode: 'MX',
		displayName: 'Mexico',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Mexico',
		quoteTemplateID: 1
	},
	{
		id: 146,
		addressFormatID: 1,
		countryCode: 'FM',
		displayName: 'Micronesia, Federated States Of',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Micronesia, Federated States Of',
		quoteTemplateID: 1
	},
	{
		id: 147,
		addressFormatID: 1,
		countryCode: 'MD',
		displayName: 'Moldova, Republic Of',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Moldova, Republic Of',
		quoteTemplateID: 1
	},
	{
		id: 148,
		addressFormatID: 1,
		countryCode: 'MC',
		displayName: 'Monaco',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Monaco',
		quoteTemplateID: 1
	},
	{
		id: 149,
		addressFormatID: 1,
		countryCode: 'MN',
		displayName: 'Mongolia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Mongolia',
		quoteTemplateID: 1
	},
	{
		id: 150,
		addressFormatID: 1,
		countryCode: 'ME',
		displayName: 'Montenegro',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Montenegro',
		quoteTemplateID: 1
	},
	{
		id: 151,
		addressFormatID: 1,
		countryCode: 'MS',
		displayName: 'Montserrat',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Montserrat',
		quoteTemplateID: 1
	},
	{
		id: 152,
		addressFormatID: 1,
		countryCode: 'MA',
		displayName: 'Morocco',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Morocco',
		quoteTemplateID: 1
	},
	{
		id: 153,
		addressFormatID: 1,
		countryCode: 'MZ',
		displayName: 'Mozambique',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Mozambique',
		quoteTemplateID: 1
	},
	{
		id: 154,
		addressFormatID: 1,
		countryCode: 'MM',
		displayName: 'Myanmar',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Myanmar',
		quoteTemplateID: 1
	},
	{
		id: 155,
		addressFormatID: 1,
		countryCode: 'NA',
		displayName: 'Namibia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Namibia',
		quoteTemplateID: 1
	},
	{
		id: 156,
		addressFormatID: 1,
		countryCode: 'NR',
		displayName: 'Nauru',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Nauru',
		quoteTemplateID: 1
	},
	{
		id: 157,
		addressFormatID: 1,
		countryCode: 'NP',
		displayName: 'Nepal',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Nepal',
		quoteTemplateID: 1
	},
	{
		id: 158,
		addressFormatID: 6,
		countryCode: 'NL',
		displayName: 'Netherlands',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Netherlands',
		quoteTemplateID: 1
	},
	{
		id: 159,
		addressFormatID: 1,
		countryCode: 'NC',
		displayName: 'New Caledonia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'New Caledonia',
		quoteTemplateID: 1
	},
	{
		id: 160,
		addressFormatID: 3,
		countryCode: 'NZ',
		displayName: 'New Zealand',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'New Zealand',
		quoteTemplateID: 1
	},
	{
		id: 161,
		addressFormatID: 1,
		countryCode: 'NI',
		displayName: 'Nicaragua',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Nicaragua',
		quoteTemplateID: 1
	},
	{
		id: 162,
		addressFormatID: 1,
		countryCode: 'NE',
		displayName: 'Niger',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Niger',
		quoteTemplateID: 1
	},
	{
		id: 163,
		addressFormatID: 1,
		countryCode: 'NG',
		displayName: 'Nigeria',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Nigeria',
		quoteTemplateID: 1
	},
	{
		id: 164,
		addressFormatID: 1,
		countryCode: 'NU',
		displayName: 'Niue',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Niue',
		quoteTemplateID: 1
	},
	{
		id: 165,
		addressFormatID: 1,
		countryCode: 'NF',
		displayName: 'Norfolk Island',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Norfolk Island',
		quoteTemplateID: 1
	},
	{
		id: 166,
		addressFormatID: 1,
		countryCode: 'MP',
		displayName: 'Northern Mariana Islands',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Northern Mariana Islands',
		quoteTemplateID: 1
	},
	{
		id: 167,
		addressFormatID: 1,
		countryCode: 'NO',
		displayName: 'Norway',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Norway',
		quoteTemplateID: 1
	},
	{
		id: 168,
		addressFormatID: 1,
		countryCode: 'OM',
		displayName: 'Oman',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Oman',
		quoteTemplateID: 1
	},
	{
		id: 169,
		addressFormatID: 1,
		countryCode: 'PK',
		displayName: 'Pakistan',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Pakistan',
		quoteTemplateID: 1
	},
	{
		id: 170,
		addressFormatID: 1,
		countryCode: 'PW',
		displayName: 'Palau',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Palau',
		quoteTemplateID: 1
	},
	{
		id: 171,
		addressFormatID: 1,
		countryCode: 'PS',
		displayName: 'Palestinian Territory, Occupied',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Palestine, State of',
		quoteTemplateID: 1
	},
	{
		id: 172,
		addressFormatID: 1,
		countryCode: 'PA',
		displayName: 'Panama',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Panama',
		quoteTemplateID: 1
	},
	{
		id: 173,
		addressFormatID: 1,
		countryCode: 'PG',
		displayName: 'Papua New Guinea',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Papua New Guinea',
		quoteTemplateID: 1
	},
	{
		id: 174,
		addressFormatID: 1,
		countryCode: 'PY',
		displayName: 'Paraguay',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Paraguay',
		quoteTemplateID: 1
	},
	{
		id: 175,
		addressFormatID: 1,
		countryCode: 'PE',
		displayName: 'Peru',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Peru',
		quoteTemplateID: 1
	},
	{
		id: 176,
		addressFormatID: 1,
		countryCode: 'PH',
		displayName: 'Philippines',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Philippines',
		quoteTemplateID: 1
	},
	{
		id: 177,
		addressFormatID: 1,
		countryCode: 'PN',
		displayName: 'Pitcairn',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Pitcairn',
		quoteTemplateID: 1
	},
	{
		id: 178,
		addressFormatID: 1,
		countryCode: 'PL',
		displayName: 'Poland',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Poland',
		quoteTemplateID: 1
	},
	{
		id: 179,
		addressFormatID: 1,
		countryCode: 'PT',
		displayName: 'Portugal',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Portugal',
		quoteTemplateID: 1
	},
	{
		id: 180,
		addressFormatID: 1,
		countryCode: 'PR',
		displayName: 'Puerto Rico',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Puerto Rico',
		quoteTemplateID: 1
	},
	{
		id: 181,
		addressFormatID: 1,
		countryCode: 'QA',
		displayName: 'Qatar',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Qatar',
		quoteTemplateID: 1
	},
	{
		id: 182,
		addressFormatID: 1,
		countryCode: 'RE',
		displayName: 'Réunion',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Réunion',
		quoteTemplateID: 1
	},
	{
		id: 183,
		addressFormatID: 1,
		countryCode: 'RO',
		displayName: 'Romania',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Romania',
		quoteTemplateID: 1
	},
	{
		id: 184,
		addressFormatID: 10,
		countryCode: 'RU',
		displayName: 'Russian Federation',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Russian Federation',
		quoteTemplateID: 1
	},
	{
		id: 185,
		addressFormatID: 1,
		countryCode: 'RW',
		displayName: 'Rwanda',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Rwanda',
		quoteTemplateID: 1
	},
	{
		id: 186,
		addressFormatID: 1,
		countryCode: 'BL',
		displayName: 'Saint Barthélemy',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Saint Barthélemy',
		quoteTemplateID: 1
	},
	{
		id: 187,
		addressFormatID: 1,
		countryCode: 'SH',
		displayName: 'Saint Helena, Ascension And Tristan Da Cunha',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Saint Helena, Ascension And Tristan Da Cunha',
		quoteTemplateID: 1
	},
	{
		id: 188,
		addressFormatID: 1,
		countryCode: 'KN',
		displayName: 'Saint Kitts And Nevis',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Saint Kitts And Nevis',
		quoteTemplateID: 1
	},
	{
		id: 189,
		addressFormatID: 1,
		countryCode: 'LC',
		displayName: 'Saint Lucia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Saint Lucia',
		quoteTemplateID: 1
	},
	{
		id: 190,
		addressFormatID: 1,
		countryCode: 'MF',
		displayName: 'Saint Martin (French Part)',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Saint Martin (French Part)',
		quoteTemplateID: 1
	},
	{
		id: 191,
		addressFormatID: 1,
		countryCode: 'PM',
		displayName: 'Saint Pierre And Miquelon',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Saint Pierre And Miquelon',
		quoteTemplateID: 1
	},
	{
		id: 192,
		addressFormatID: 1,
		countryCode: 'VC',
		displayName: 'Saint Vincent And The Grenadines',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Saint Vincent And The Grenadines',
		quoteTemplateID: 1
	},
	{
		id: 193,
		addressFormatID: 1,
		countryCode: 'WS',
		displayName: 'Samoa',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Samoa',
		quoteTemplateID: 1
	},
	{
		id: 194,
		addressFormatID: 1,
		countryCode: 'SM',
		displayName: 'San Marino',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'San Marino',
		quoteTemplateID: 1
	},
	{
		id: 195,
		addressFormatID: 1,
		countryCode: 'ST',
		displayName: 'Sao Tome And Principe',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Sao Tome And Principe',
		quoteTemplateID: 1
	},
	{
		id: 196,
		addressFormatID: 1,
		countryCode: 'SA',
		displayName: 'Saudi Arabia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Saudi Arabia',
		quoteTemplateID: 1
	},
	{
		id: 197,
		addressFormatID: 1,
		countryCode: 'SN',
		displayName: 'Senegal',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Senegal',
		quoteTemplateID: 1
	},
	{
		id: 198,
		addressFormatID: 1,
		countryCode: 'RS',
		displayName: 'Serbia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Serbia',
		quoteTemplateID: 1
	},
	{
		id: 199,
		addressFormatID: 1,
		countryCode: 'SC',
		displayName: 'Seychelles',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Seychelles',
		quoteTemplateID: 1
	},
	{
		id: 200,
		addressFormatID: 1,
		countryCode: 'SL',
		displayName: 'Sierra Leone',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Sierra Leone',
		quoteTemplateID: 1
	},
	{
		id: 201,
		addressFormatID: 1,
		countryCode: 'SG',
		displayName: 'Singapore',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Singapore',
		quoteTemplateID: 1
	},
	{
		id: 202,
		addressFormatID: 1,
		countryCode: 'SX',
		displayName: 'Sint Maarten (Dutch Part)',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Sint Maarten (Dutch Part)',
		quoteTemplateID: 1
	},
	{
		id: 203,
		addressFormatID: 1,
		countryCode: 'SK',
		displayName: 'Slovakia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Slovakia',
		quoteTemplateID: 1
	},
	{
		id: 204,
		addressFormatID: 1,
		countryCode: 'SI',
		displayName: 'Slovenia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Slovenia',
		quoteTemplateID: 1
	},
	{
		id: 205,
		addressFormatID: 1,
		countryCode: 'SB',
		displayName: 'Solomon Islands',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Solomon Islands',
		quoteTemplateID: 1
	},
	{
		id: 206,
		addressFormatID: 1,
		countryCode: 'SO',
		displayName: 'Somalia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Somalia',
		quoteTemplateID: 1
	},
	{
		id: 207,
		addressFormatID: 11,
		countryCode: 'ZA',
		displayName: 'South Africa',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'South Africa',
		quoteTemplateID: 1
	},
	{
		id: 208,
		addressFormatID: 1,
		countryCode: 'GS',
		displayName: 'South Georgia And The South Sandwich Islands',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'South Georgia And The South Sandwich Islands',
		quoteTemplateID: 1
	},
	{
		id: 209,
		addressFormatID: 1,
		countryCode: 'SS',
		displayName: 'South Sudan',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'South Sudan',
		quoteTemplateID: 1
	},
	{
		id: 210,
		addressFormatID: 1,
		countryCode: 'ES',
		displayName: 'Spain',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Spain',
		quoteTemplateID: 1
	},
	{
		id: 211,
		addressFormatID: 1,
		countryCode: 'LK',
		displayName: 'Sri Lanka',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Sri Lanka',
		quoteTemplateID: 1
	},
	{
		id: 212,
		addressFormatID: 1,
		countryCode: 'SD',
		displayName: 'Sudan',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Sudan',
		quoteTemplateID: 1
	},
	{
		id: 213,
		addressFormatID: 1,
		countryCode: 'SR',
		displayName: 'Suriname',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Suriname',
		quoteTemplateID: 1
	},
	{
		id: 214,
		addressFormatID: 1,
		countryCode: 'SJ',
		displayName: 'Svalbard And Jan Mayen',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Svalbard And Jan Mayen',
		quoteTemplateID: 1
	},
	{
		id: 215,
		addressFormatID: 1,
		countryCode: 'SZ',
		displayName: 'Swaziland',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Swaziland',
		quoteTemplateID: 1
	},
	{
		id: 216,
		addressFormatID: 1,
		countryCode: 'SE',
		displayName: 'Sweden',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Sweden',
		quoteTemplateID: 1
	},
	{
		id: 217,
		addressFormatID: 6,
		countryCode: 'CH',
		displayName: 'Switzerland',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Switzerland',
		quoteTemplateID: 1
	},
	{
		id: 218,
		addressFormatID: 1,
		countryCode: 'SY',
		displayName: 'Syrian Arab Republic',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Syrian Arab Republic',
		quoteTemplateID: 1
	},
	{
		id: 219,
		addressFormatID: 1,
		countryCode: 'TW',
		displayName: 'Taiwan, Province Of China',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Taiwan, Province Of China',
		quoteTemplateID: 1
	},
	{
		id: 220,
		addressFormatID: 1,
		countryCode: 'TJ',
		displayName: 'Tajikistan',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Tajikistan',
		quoteTemplateID: 1
	},
	{
		id: 221,
		addressFormatID: 1,
		countryCode: 'TZ',
		displayName: 'Tanzania, United Republic Of',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Tanzania, United Republic Of',
		quoteTemplateID: 1
	},
	{
		id: 222,
		addressFormatID: 1,
		countryCode: 'TH',
		displayName: 'Thailand',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Thailand',
		quoteTemplateID: 1
	},
	{
		id: 223,
		addressFormatID: 1,
		countryCode: 'TL',
		displayName: 'Timor-Leste',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Timor-Leste',
		quoteTemplateID: 1
	},
	{
		id: 224,
		addressFormatID: 1,
		countryCode: 'TG',
		displayName: 'Togo',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Togo',
		quoteTemplateID: 1
	},
	{
		id: 225,
		addressFormatID: 1,
		countryCode: 'TK',
		displayName: 'Tokelau',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Tokelau',
		quoteTemplateID: 1
	},
	{
		id: 226,
		addressFormatID: 1,
		countryCode: 'TO',
		displayName: 'Tonga',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Tonga',
		quoteTemplateID: 1
	},
	{
		id: 227,
		addressFormatID: 1,
		countryCode: 'TT',
		displayName: 'Trinidad And Tobago',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Trinidad And Tobago',
		quoteTemplateID: 1
	},
	{
		id: 228,
		addressFormatID: 1,
		countryCode: 'TN',
		displayName: 'Tunisia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Tunisia',
		quoteTemplateID: 1
	},
	{
		id: 229,
		addressFormatID: 1,
		countryCode: 'TR',
		displayName: 'Turkey',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Turkey',
		quoteTemplateID: 1
	},
	{
		id: 230,
		addressFormatID: 1,
		countryCode: 'TM',
		displayName: 'Turkmenistan',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Turkmenistan',
		quoteTemplateID: 1
	},
	{
		id: 231,
		addressFormatID: 1,
		countryCode: 'TC',
		displayName: 'Turks And Caicos Islands',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Turks And Caicos Islands',
		quoteTemplateID: 1
	},
	{
		id: 232,
		addressFormatID: 1,
		countryCode: 'TV',
		displayName: 'Tuvalu',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Tuvalu',
		quoteTemplateID: 1
	},
	{
		id: 233,
		addressFormatID: 1,
		countryCode: 'UG',
		displayName: 'Uganda',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Uganda',
		quoteTemplateID: 1
	},
	{
		id: 234,
		addressFormatID: 1,
		countryCode: 'UA',
		displayName: 'Ukraine',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Ukraine',
		quoteTemplateID: 1
	},
	{
		id: 235,
		addressFormatID: 1,
		countryCode: 'AE',
		displayName: 'United Arab Emirates',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'United Arab Emirates',
		quoteTemplateID: 1
	},
	{
		id: 236,
		addressFormatID: 1,
		countryCode: 'GB',
		displayName: 'United Kingdom',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'United Kingdom',
		quoteTemplateID: 1
	},
	{
		id: 237,
		addressFormatID: 1,
		countryCode: 'US',
		displayName: 'United States',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: true,
		name: 'United States',
		quoteTemplateID: 1
	},
	{
		id: 238,
		addressFormatID: 1,
		countryCode: 'UM',
		displayName: 'United States Minor Outlying Islands',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'United States Minor Outlying Islands',
		quoteTemplateID: 1
	},
	{
		id: 239,
		addressFormatID: 1,
		countryCode: 'UY',
		displayName: 'Uruguay',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Uruguay',
		quoteTemplateID: 1
	},
	{
		id: 240,
		addressFormatID: 1,
		countryCode: 'UZ',
		displayName: 'Uzbekistan',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Uzbekistan',
		quoteTemplateID: 1
	},
	{
		id: 241,
		addressFormatID: 1,
		countryCode: 'VU',
		displayName: 'Vanuatu',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Vanuatu',
		quoteTemplateID: 1
	},
	{
		id: 242,
		addressFormatID: 1,
		countryCode: 'VE',
		displayName: 'Venezuela, Bolivarian Republic Of',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Venezuela, Bolivarian Republic Of',
		quoteTemplateID: 1
	},
	{
		id: 243,
		addressFormatID: 1,
		countryCode: 'VN',
		displayName: 'Viet Nam',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Viet Nam',
		quoteTemplateID: 1
	},
	{
		id: 244,
		addressFormatID: 1,
		countryCode: 'VG',
		displayName: 'Virgin Islands, British',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Virgin Islands, British',
		quoteTemplateID: 1
	},
	{
		id: 245,
		addressFormatID: 1,
		countryCode: 'VI',
		displayName: 'Virgin Islands, U.S.',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Virgin Islands, U.S.',
		quoteTemplateID: 1
	},
	{
		id: 246,
		addressFormatID: 1,
		countryCode: 'WF',
		displayName: 'Wallis And Futuna',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Wallis And Futuna',
		quoteTemplateID: 1
	},
	{
		id: 247,
		addressFormatID: 1,
		countryCode: 'EH',
		displayName: 'Western Sahara',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Western Sahara',
		quoteTemplateID: 1
	},
	{
		id: 248,
		addressFormatID: 1,
		countryCode: 'YE',
		displayName: 'Yemen',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Yemen',
		quoteTemplateID: 1
	},
	{
		id: 249,
		addressFormatID: 1,
		countryCode: 'ZM',
		displayName: 'Zambia',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Zambia',
		quoteTemplateID: 1
	},
	{
		id: 250,
		addressFormatID: 1,
		countryCode: 'ZW',
		displayName: 'Zimbabwe',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'Zimbabwe',
		quoteTemplateID: 1
	},
	{
		id: 251,
		addressFormatID: 1,
		countryCode: 'XK',
		displayName: 'Kosovo',
		invoiceTemplateID: 102,
		isActive: true,
		isDefaultCountry: false,
		name: 'KOSOVO',
		quoteTemplateID: 1
	}
]
export { tickets,  company, devices, locations, COUNTRY_DATA }
