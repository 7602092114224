/**
 * Company modules helper
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import React, { useEffect } from 'react'
import { COUNTRY_DATA } from 'data'
import { useSelector, useDispatch } from 'react-redux'

// modules
import apiStatus from 'modules/status'
import { COMPANY_TYPES } from 'constants'
// actions
import {
	companyActions,
	deviceActions,
	serviceRequestActions,
	userActions
} from 'actions'
// reducers
import { companiesSelectors, companySelectors } from 'reducers'
//utils
import moment from 'moment'
export const normalizeCompany = company => {
	const { userDefinedFields } = company
	let customFields = {}
	const customData = [
		{
			label: 'Google käytössä',
			name: 'googleWorkplace'
		},
		{
			label: 'Henkilöstömäärä',
			name: 'employeeNumber'
		},
		{
			label: 'N-central Customer ID',
			name: 'customerId'
		},
		{
			label: 'Office 365 käytössä',
			name: 'office365'
		}
	]
	customData.forEach(item => {
		const foundField = userDefinedFields.find(
			field => item.label === field.name
		)
		if (foundField) {
			let returnValue = foundField.value
			//normalize case that employeeNumber = 10.000
			if (
				item.name === 'employeeNumber' &&
				foundField.value &&
				foundField.value.includes('.')
			) {
				let arrayValues = foundField.value.split('.')
				returnValue = arrayValues[0]
			}

			customFields[item.name] = returnValue
		}
	})
	let companyTypeName = ''
	if (company.companyType) {
		const foundType = COMPANY_TYPES.find(
			item => item.value == company.companyType
		)
		if (foundType) {
			companyTypeName = foundType.label
		}
	}
	return { ...company, ...customFields, companyTypeName }
}

export const normalizeLocation = location => {
	const { countryID } = location
	const foundCountry = COUNTRY_DATA.find(country => country.id === countryID)
	return {
		...location,
		countryLabel: foundCountry ? foundCountry.displayName : 'No country'
	}
}
/**
 * companies hook
 * Fetch companies from server and save data to the state
 * once the request is resolved
 *
 * @return {Array} a list of companies fetched from server
 */

const useCompanies = () => {
	const dispatch = useDispatch()

	const fetchedCompanies = useSelector(state =>
		companiesSelectors.getCompanies(state.companies)
	)
	const itemsPerPage = useSelector(state =>
		companiesSelectors.getItemsPerPage(state.companies)
	)
	const status = useSelector(state =>
		companiesSelectors.getApiStatus(state.companies)
	)
	const [companies, setCompanies] = React.useState(fetchedCompanies)

	React.useEffect(() => {
		dispatch(companyActions.getAll({ isActive: true }))
	}, [])
	React.useEffect(() => {
		if (status === apiStatus.resolved) {
			setCompanies(fetchedCompanies)
		}
	}, [status, fetchedCompanies])

	return { companies, status, itemsPerPage }
}

/**
 * company hook
 * Fetch all data related when company is changed
 * once the request is resolved
 *
 */
const useCompany = () => {
	const dispatch = useDispatch()
	const company = useSelector(state =>
		companySelectors.getCompany(state.company)
	)
	useEffect(() => {
		if (company) {
			const { autoTaskId: companyId } = company
			dispatch(deviceActions.clearDevices())
			dispatch(userActions.clearUsers())
			dispatch(companyActions.clearContactsAndLocations())

			//devices
			if (
				company &&
				company.userDefinedFields &&
				company.userDefinedFields.length > 0
			) {
				dispatch(
					deviceActions.getAll(company.id)
				)
			}
			//users
			dispatch(userActions.getCompanyUsers(companyId, { isRequiredOnly: true }))
			//company
			dispatch(
				companyActions.getCompanyContacts(companyId, { isRequiredOnly: true })
			)
			dispatch(companyActions.getLocations(companyId))
		}
	}, [company])

	return { company }
}
export default { normalizeCompany, normalizeLocation, useCompanies, useCompany }
