/**
 * company reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { loginTypes, companyTypes as types } from '../actions/types'
import { AULA_ROLES } from 'constants'
import { companyModule } from './../modules'

const initialState = {
	company: null,
	contacts: [],
	locations: [],
	error: null
}
const normalizeLocations = locations => {
	if (!locations || locations.length === 0) {
		return []
	}
	return locations.map(companyModule.normalizeLocation)
}
export const companyReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.getLocations:
			return { ...state }
		case types.getLocationsSuccess:
			return { ...state, locations: normalizeLocations(action.payload.data) }
		case types.getLocationsFail:
			return { ...state }
		case types.getCompanyContacts:
			return { ...state }
		case types.getCompanyContactsSuccess:
			return { ...state, contacts: action.payload.data.items }
		case types.getCompanyContactsFail:
			return { ...state }
		case types.getCompanyById:
			return { ...state }
		case types.getCompanyByIdSuccess:
			localStorage.setItem('companyId', action.payload.data.autoTaskId)
			return {
				...state,
				company: companyModule.normalizeCompany(action.payload.data)
			}
		case types.getCompanyByIdFail:
			return { ...state }
		case types.clearContactsAndLocations:
			return { ...state, contacts: [], locations: [] }
		default:
			return state
	}
}

export const companySelectors = {
	getCompany: (state = initialState) => state.company,
	getLocations: (state = initialState) => state.locations,
	getContacts: (state = initialState) => state.contacts
}
