/**
 * device module
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2021
 */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
// actions
import { deviceActions } from 'actions'
// reducers
import { devicesSelectors, companySelectors } from 'reducers'
import apiStatus from 'modules/status'
import moment from 'moment'
import { WARRANTY_VALUE, WARRANTY_EXPIRING_DAYS, OS_VALUE } from 'constants'

/**
 *
 *
 * @param {Object}
 *
 * @return {Array}
 */
const useDevices = () => {
	const dispatch = useDispatch()
	const fetchedDevices = useSelector(state =>
		devicesSelectors.getDevices(state.devices)
	)

	const itemsPerPage = useSelector(state =>
		devicesSelectors.getItemsPerPage(state.devices)
	)

	const companyId = localStorage.getItem('companyId')
	const company = useSelector(state =>
		companySelectors.getCompany(state.company)
	)
	const status = useSelector(state =>
		devicesSelectors.getApiStatus(state.devices)
	)
	const [devices, setDevices] = React.useState(fetchedDevices)
	const isEmpty = !devices || !Array.isArray(devices) || devices.length === 0

	React.useEffect(() => {
		if (
			company &&
			company.userDefinedFields &&
			company.userDefinedFields.length > 0
		) {
			dispatch(
				deviceActions.getAll(
					company.id,
					!!(fetchedDevices && fetchedDevices.length)
				)
			)
		}
	}, [])

	React.useEffect(() => {
		if (status === apiStatus.resolved) {
			setDevices(fetchedDevices)
		}
	}, [status, fetchedDevices])

	return {
		devices,
		status,
		itemsPerPage
	}
}

const getExpiredValue = warrantyDate => {
	if (!warrantyDate) {
		return WARRANTY_VALUE.undefined
	}
	const today = moment()
	if (!warrantyDate || moment(warrantyDate).isBefore(today)) {
		return WARRANTY_VALUE.expired
	}
	if (
		moment(today).isBefore(warrantyDate) &&
		moment(warrantyDate).diff(today, 'days') < WARRANTY_EXPIRING_DAYS
	) {
		return WARRANTY_VALUE.expiring
	}
	return WARRANTY_VALUE.supported
}

const formatBytes = (bytes, k, label) => {
	if (!bytes || bytes === 0)
		return ''

	return Math.ceil(parseFloat(bytes / k)) + ` ${label}`
}

/**
 *
 *
 * @param {Object}
 *
 * @return {Array}
 */
const normalizeDevice = (device) => {
	device.totalphysicalmemory  = formatBytes(device.totalphysicalmemory, 1024*1024*1024, 'GB')
	device.physicaldrive 		= formatBytes(device.physicaldrive, 1024*1024*1024, 'GB')
	device.expireValue 			= getExpiredValue(device.warrantyexpirydate)
	device.warrantyexpirydate 	= device.warrantyexpirydate ? new Date(device.warrantyexpirydate).toLocaleDateString() : ''

	return device
}

export default { useDevices, normalizeDevice }
