/**
 * Login reducer
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import { loginTypes as types } from '../actions/types'

const initialState = {
	loading: true,
	error: null
}

export const loginReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.login:
			return {
				...state,
				loading: true
			}

		case types.loginSuccess:
			return {
				...state,
				loading: false
			}

		case types.loginFail:
			return {
				...state,
				loading: false,
				error: action.error
			}

		default:
			return state
	}
}

export const loginSelectors = {
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error
}
