/**
 * Order modules helper
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
// actions
import { orderActions } from 'actions'
// reducers
import { ordersSelectors, companySelectors } from 'reducers'
// module
import apiStatus from 'modules/status'
import moment from 'moment'
//constants
import { AUTOTASK_ROLES } from 'constants'
// utils
import { arrayUtils } from 'utils'

/**
 * requests hook
 * Fetch service request from server and save data to the state
 * once the request is resolved
 *
 * @return {Array} a list of service request fetched from server
 */

const useOrders = () => {
	const dispatch = useDispatch()

	const fetchOrders = useSelector(state => ordersSelectors.getAll(state.orders))
	const itemsPerPage = useSelector(state =>
		ordersSelectors.getItemsPerPage(state.orders)
	)

	const companyId = localStorage.getItem('companyId')
	const company = useSelector(state =>
		companySelectors.getCompany(state.company)
	)
	const status = useSelector(state =>
		ordersSelectors.getApiStatus(state.orders)
	)
	const [orders, setOrders] = React.useState(fetchOrders)

	React.useEffect(() => {
		if (company) {
			// dispatch(serviceRequestActions.clearTickets())
			getOrdersRequest()
		}
	}, [company])
	const getOrdersRequest = async () => {
		if (company) {
			try {
				await dispatch(orderActions.getAll(companyId))
			} catch (err) {}
		}
	}
	React.useEffect(() => {
		if (status === apiStatus.resolved) {
			setOrders(fetchOrders)
		}
	}, [status, fetchOrders])

	return { orders, status, itemsPerPage }
}
export default { useOrders }
