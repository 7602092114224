/**
 * companies reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { companyTypes as types } from '../actions/types'
import apiStatus from 'modules/status'
import { DEFAULT_COMPANIES_PER_PAGE } from 'constants'
const initialState = {
	companies: [],
	status: apiStatus.idle,
	itemsPerPage: DEFAULT_COMPANIES_PER_PAGE
}

export const companiesReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.getAll:
			return { ...state, status: apiStatus.pending }
		case types.getAllSuccess:
			if (action.payload.config.reduxSourceAction.canSave) {
				return {
					...state,
					companies: action.payload.data.items,
					status: apiStatus.resolved
				}
			}
			return state
		case types.getAllFail:
			return { ...state, status: apiStatus.reject }
		default:
			return state
	}
}

export const companiesSelectors = {
	getCompanies: (state = initialState) => state.companies,
	getApiStatus: (state = initialState) => state.status,
	getItemsPerPage: (state = initialState) => state.itemsPerPage
}
