/**
 * Ticket reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { serviceRequestTypes as types } from '../actions/types'
import { tickets } from '../data'
import apiStatus from 'modules/status'
import { DEFAULT_SERVICE_REQUESTS_PER_PAGE } from 'constants'
const initialState = {
	requests: [],
	prevPageUrl: null,
	nextPageUrl: null,
	itemsPerPage: DEFAULT_SERVICE_REQUESTS_PER_PAGE,
	status: apiStatus.idle,
	error: null
}

export const serviceRequestsReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.changeItemsPerPage:
			return { ...state, itemsPerPage: action.amount }
		case types.getAll:
			if (action.isBackground) {
				return { ...state }
			}
			return { ...state, status: apiStatus.pending }
		case types.getAllSuccess:
			return {
				...state,
				status: apiStatus.resolved,
				requests: action.payload.data.items,
				prevPageUrl: action.payload.data.pageDetails.prevPageUrl,
				nextPageUrl: action.payload.data.pageDetails.nextPageUrl
			}
		case types.getAllFail:
			return { ...state, status: apiStatus.reject, requests: [] }
		case types.clearTickets:
			return { ...state, requests: [] }

		default:
			return state
	}
}

export const serviceRequestsSelectors = {
	getRequests: (state = initialState) => state.requests,
	getApiStatus: (state = initialState) => state.status,
	getPrevPageUrl: (state = initialState) => state.prevPageUrl,
	getNextPageUrl: (state = initialState) => state.nextPageUrl,
	getItemsPerPage: (state = initialState) => state.itemsPerPage
}
